/**
 * @generated SignedSource<<8f8505eea836f785a7db275a4a74bae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderStatusEnum = "canceled" | "completed" | "waybill_required" | "%future added value";
export type DeliveryOrderTypeEnum = "gift" | "order" | "%future added value";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type DeliveryOrderFilter = {
  AND?: DeliveryOrderFilter | null;
  NOT?: DeliveryOrderFilter | null;
  OR?: DeliveryOrderFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  deliveredById_Exact?: string | null;
  deliveredById_In?: ReadonlyArray<string> | null;
  delivered_Gte?: any | null;
  delivered_Lte?: any | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  search?: string | null;
  status_Exact?: DeliveryOrderStatusEnum | null;
  status_In?: ReadonlyArray<DeliveryOrderStatusEnum> | null;
  stuffId_Exact?: string | null;
  stuffId_In?: ReadonlyArray<string> | null;
  type_Exact?: DeliveryOrderTypeEnum | null;
  type_In?: ReadonlyArray<DeliveryOrderTypeEnum> | null;
};
export type DeliveryOrderOrder = {
  created?: Ordering | null;
  delivered?: Ordering | null;
  id?: Ordering | null;
};
export type DeliveryOrderWindowPaginatorRefreshQuery$variables = {
  filters?: DeliveryOrderFilter | null;
  order?: DeliveryOrderOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type DeliveryOrderWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderWindowPaginator_query">;
};
export type DeliveryOrderWindowPaginatorRefreshQuery = {
  response: DeliveryOrderWindowPaginatorRefreshQuery$data;
  variables: DeliveryOrderWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliveryOrderWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "DeliveryOrderWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeliveryOrderWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliveryOrderConnection",
        "kind": "LinkedField",
        "name": "deliveryOrders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DeliveryOrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "DeliveryOrder",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stuff",
                    "kind": "LinkedField",
                    "name": "stuff",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeliveryWaybill",
                    "kind": "LinkedField",
                    "name": "deliveryWaybill",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "waybillNumber",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DeliveryAddress",
                    "kind": "LinkedField",
                    "name": "deliveryAddress",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recipientName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "recipientNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "addressDetail",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "delivered",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "deliveredBy",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b6b4e40afd66fb786621333e2c420a3",
    "id": null,
    "metadata": {},
    "name": "DeliveryOrderWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query DeliveryOrderWindowPaginatorRefreshQuery(\n  $filters: DeliveryOrderFilter\n  $order: DeliveryOrderOrder\n  $page: Int = 1\n  $pageSize: Int\n) {\n  ...DeliveryOrderWindowPaginator_query_36liK3\n}\n\nfragment DeliveryOrderConnectionDataTable_deliveryOrderConnection on DeliveryOrderConnection {\n  edges {\n    node {\n      id\n      sequence\n      ...DeliveryOrderStatusLabel_deliveryOrder\n      stuff {\n        id\n        title\n      }\n      created\n      createdBy {\n        id\n        ...UserAvatarText_user\n      }\n      type\n      deliveryWaybill {\n        waybillNumber\n        id\n      }\n      deliveryAddress {\n        recipientName\n        recipientNumber\n        address\n        addressDetail\n        id\n      }\n      delivered\n      deliveredBy {\n        id\n        ...UserAvatarText_user\n      }\n    }\n  }\n}\n\nfragment DeliveryOrderStatusLabel_deliveryOrder on DeliveryOrder {\n  status\n}\n\nfragment DeliveryOrderWindowPaginator_query_36liK3 on Query {\n  deliveryOrders(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...DeliveryOrderConnectionDataTable_deliveryOrderConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "a022bc64d45695031506c2617f4a829f";

export default node;
