/**
 * @generated SignedSource<<177a9a3983ea5a7c4e7431db5a9fea72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationTypeEnum = "bill_completed" | "%future added value";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type NotificationOrder = {
  created?: Ordering | null;
};
export type NotificationFilter = {
  AND?: NotificationFilter | null;
  NOT?: NotificationFilter | null;
  OR?: NotificationFilter | null;
  checked_Isnull?: boolean | null;
  created_Gte?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  nodeId_Exact?: string | null;
  nodeId_In?: ReadonlyArray<string> | null;
  read_Isnull?: boolean | null;
  type_In?: ReadonlyArray<NotificationTypeEnum> | null;
};
export type NotificationHeaderItem_notificationsQuery$variables = {
  filters?: NotificationFilter | null;
  first?: number | null;
  order?: NotificationOrder | null;
};
export type NotificationHeaderItem_notificationsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NotificationPaginator_query">;
};
export type NotificationHeaderItem_notificationsQuery = {
  response: NotificationHeaderItem_notificationsQuery$data;
  variables: NotificationHeaderItem_notificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationHeaderItem_notificationsQuery",
    "selections": [
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "NotificationPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotificationHeaderItem_notificationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "NotificationConnection",
        "kind": "LinkedField",
        "name": "notifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Notification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nodeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "checked",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notificationType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "NotificationsPaginator_query_notifications",
        "kind": "LinkedHandle",
        "name": "notifications"
      }
    ]
  },
  "params": {
    "cacheID": "337a32902c2b1374f464a5f51eadae76",
    "id": null,
    "metadata": {},
    "name": "NotificationHeaderItem_notificationsQuery",
    "operationKind": "query",
    "text": "query NotificationHeaderItem_notificationsQuery(\n  $first: Int\n  $order: NotificationOrder\n  $filters: NotificationFilter\n) {\n  ...NotificationPaginator_query_3Ta2VO\n}\n\nfragment NotificationItem_notification on Notification {\n  id\n  title\n  notificationType\n  description\n  checked\n  created\n}\n\nfragment NotificationPaginator_query_3Ta2VO on Query {\n  notifications(filters: $filters, order: $order, first: $first) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        nodeId\n        typename\n        checked\n        ...NotificationItem_notification\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b57155d67246e5dd70bfcdb523e0459a";

export default node;
