import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { AnchorHTMLAttributes } from 'react';

type LinkProps = {} & NextLinkProps & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target'>;

const Link = ({ children, target, ...props }: React.PropsWithChildren<LinkProps>) => (
  <NextLink
    {...props}
    passHref
    target={target}
    style={{ margin: 0, padding: 0, border: 0, fontSize: '100%', font: 'inherit', verticalAlign: 'baseline' }}
  >
    {children}
  </NextLink>
);

export default Link;
export type { LinkProps };
