/**
 * @generated SignedSource<<3629d57c322eb5d78aec2dd360fc4d8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeEnum = "first_final" | "first_mid" | "second_final" | "second_mid" | "%future added value";
export type ExamYearEnum = "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "%future added value";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type SchoolExamSourceStatusEnum = "published" | "scrap" | "upload_required" | "%future added value";
export type SchoolRegionEnum = "busan" | "chungbuk" | "chungnam" | "daegu" | "daejeon" | "gangwon" | "gwangju" | "gyeongbuk" | "gyeonggi" | "gyeongnam" | "incheon" | "jeju" | "jeongbuk" | "jeonnam" | "sejong" | "seoul" | "ulsan" | "%future added value";
export type SchoolYearEnum = "high_1" | "high_2" | "high_3" | "middle_1" | "middle_2" | "middle_3" | "%future added value";
export type SchoolExamSourceFilter = {
  AND?: SchoolExamSourceFilter | null;
  NOT?: SchoolExamSourceFilter | null;
  OR?: SchoolExamSourceFilter | null;
  assignedToId_Exact?: string | null;
  assignedToId_In?: ReadonlyArray<string> | null;
  assignedTo_Exists?: boolean | null;
  assignedTo_Exists_In?: ReadonlyArray<boolean> | null;
  completedById_In?: ReadonlyArray<string> | null;
  completedBy_Exists_In?: ReadonlyArray<boolean> | null;
  examType_In?: ReadonlyArray<ExamTypeEnum> | null;
  examYear_In?: ReadonlyArray<ExamYearEnum> | null;
  innerPdf_Exists_In?: ReadonlyArray<boolean> | null;
  pdf_Exists_In?: ReadonlyArray<boolean> | null;
  region_In?: ReadonlyArray<SchoolRegionEnum> | null;
  schoolId_In?: ReadonlyArray<string> | null;
  schoolId_Not_In?: ReadonlyArray<string> | null;
  schoolYear_In?: ReadonlyArray<SchoolYearEnum> | null;
  search?: string | null;
  status_In?: ReadonlyArray<SchoolExamSourceStatusEnum> | null;
};
export type SchoolExamScrapOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  order?: Ordering | null;
};
export type SchoolExamSourceImageExportDialog_schoolExamSourcesQuery$variables = {
  filters?: SchoolExamSourceFilter | null;
  scrapCount?: number | null;
  scrapOrder?: SchoolExamScrapOrder | null;
};
export type SchoolExamSourceImageExportDialog_schoolExamSourcesQuery$data = {
  readonly schoolExamSources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly sequence: number;
        readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceExportedPreview_schoolExamSource">;
      };
    }>;
  };
};
export type SchoolExamSourceImageExportDialog_schoolExamSourcesQuery = {
  response: SchoolExamSourceImageExportDialog_schoolExamSourcesQuery$data;
  variables: SchoolExamSourceImageExportDialog_schoolExamSourcesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scrapCount"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scrapOrder"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sequence",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamSourceImageExportDialog_schoolExamSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSourceConnection",
        "kind": "LinkedField",
        "name": "schoolExamSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolExamSourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchoolExamSource",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "scrapCount",
                        "variableName": "scrapCount"
                      },
                      {
                        "kind": "Variable",
                        "name": "scrapOrder",
                        "variableName": "scrapOrder"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "SchoolExamSourceExportedPreview_schoolExamSource"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamSourceImageExportDialog_schoolExamSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSourceConnection",
        "kind": "LinkedField",
        "name": "schoolExamSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolExamSourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchoolExamSource",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "School",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "scrapCount"
                      },
                      {
                        "kind": "Variable",
                        "name": "order",
                        "variableName": "scrapOrder"
                      }
                    ],
                    "concreteType": "SchoolExamScrapConnection",
                    "kind": "LinkedField",
                    "name": "scraps",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchoolExamScrapEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchoolExamScrap",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "scrap",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "objectUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "852d7fc8921cfde36dde8b7a75e4e48d",
    "id": null,
    "metadata": {},
    "name": "SchoolExamSourceImageExportDialog_schoolExamSourcesQuery",
    "operationKind": "query",
    "text": "query SchoolExamSourceImageExportDialog_schoolExamSourcesQuery(\n  $filters: SchoolExamSourceFilter\n  $scrapCount: Int\n  $scrapOrder: SchoolExamScrapOrder\n) {\n  schoolExamSources(filters: $filters) {\n    edges {\n      node {\n        id\n        sequence\n        ...SchoolExamSourceExportedPreview_schoolExamSource_17JTyD\n      }\n    }\n  }\n}\n\nfragment SchoolExamSourceExportedPreview_schoolExamSource_17JTyD on SchoolExamSource {\n  id\n  school {\n    id\n    title\n  }\n  title\n  scraps(order: $scrapOrder, first: $scrapCount) {\n    edges {\n      node {\n        id\n        scrap {\n          id\n          objectUrl\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "111687a4a7c468d37899ad5189479ebf";

export default node;
