/**
 * @generated SignedSource<<a0560e0c5189b2d3387f88506e4f6eeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type FormulaSetHistoryOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type FormulaSetFormulaSetHistoryPaginatorRefreshQuery$variables = {
  after?: string | null;
  first?: number | null;
  id: string;
  order?: FormulaSetHistoryOrder | null;
};
export type FormulaSetFormulaSetHistoryPaginatorRefreshQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"FormulaSetFormulaSetHistoryPaginator_formulaSet">;
  };
};
export type FormulaSetFormulaSetHistoryPaginatorRefreshQuery = {
  response: FormulaSetFormulaSetHistoryPaginatorRefreshQuery$data;
  variables: FormulaSetFormulaSetHistoryPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": {
    "created": "DESC"
  },
  "kind": "LocalArgument",
  "name": "order"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FormulaSetFormulaSetHistoryPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "FormulaSetFormulaSetHistoryPaginator_formulaSet"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "FormulaSetFormulaSetHistoryPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "FormulaSetHistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormulaSetHistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FormulaSetHistory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "picture",
                                "storageKey": null
                              },
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isDeleted",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "created",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "difference",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isDifference"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FormulaDifference",
                                    "kind": "LinkedField",
                                    "name": "formulas",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Diff",
                                        "kind": "LinkedField",
                                        "name": "text",
                                        "plural": false,
                                        "selections": (v8/*: any*/),
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Diff",
                                        "kind": "LinkedField",
                                        "name": "title",
                                        "plural": false,
                                        "selections": (v8/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Diff",
                                    "kind": "LinkedField",
                                    "name": "description",
                                    "plural": false,
                                    "selections": (v8/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "FormulaSetDifference",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "order"
                ],
                "handle": "connection",
                "key": "FormulaSetFormulaSetHistoryPaginator_formulaSet_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "type": "FormulaSet",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "31e2226e2286e77f16f683dc1fe970c6",
    "id": null,
    "metadata": {},
    "name": "FormulaSetFormulaSetHistoryPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query FormulaSetFormulaSetHistoryPaginatorRefreshQuery(\n  $after: String\n  $first: Int = 10\n  $order: FormulaSetHistoryOrder = {created: DESC}\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...FormulaSetFormulaSetHistoryPaginator_formulaSet_gOVF5\n    id\n  }\n}\n\nfragment DiffItem_diff on Diff {\n  before\n  after\n}\n\nfragment FormulaDifferenceItemList_formulaDifference on FormulaDifference {\n  text {\n    ...DiffItem_diff\n  }\n  title {\n    ...DiffItem_diff\n  }\n}\n\nfragment FormulaSetDifferenceItemList_formulaSetDifference on Difference {\n  __isDifference: __typename\n  ... on FormulaSetDifference {\n    formulas {\n      ...FormulaDifferenceItemList_formulaDifference\n    }\n    description {\n      ...DiffItem_diff\n    }\n  }\n}\n\nfragment FormulaSetFormulaSetHistoryPaginator_formulaSet_gOVF5 on FormulaSet {\n  histories(order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        type\n        ...FormulaSetHistoryDialog_formulaSetHistory\n        ...FormulaSetHistoryTimelineItem_formulaSetHistory\n        __typename\n      }\n    }\n  }\n  id\n}\n\nfragment FormulaSetHistoryDialog_formulaSetHistory on FormulaSetHistory {\n  createdBy {\n    name\n    picture\n    id\n  }\n  created\n  difference {\n    __typename\n    ...FormulaSetDifferenceItemList_formulaSetDifference\n  }\n}\n\nfragment FormulaSetHistoryTimelineItem_formulaSetHistory on FormulaSetHistory {\n  id\n  createdBy {\n    ...UserAvatarText_user\n    id\n  }\n  created\n  type\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "43d87a99dde4c85a1e7c7f981ebdaa0d";

export default node;
