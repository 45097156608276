/**
 * @generated SignedSource<<9b1d49c27dccd94f49d1a067f181dd4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type UserTeamEnum = "contents" | "designers" | "developers" | "freelancer" | "ga" | "growth" | "%future added value";
export type UserFilter = {
  AND?: UserFilter | null;
  NOT?: UserFilter | null;
  OR?: UserFilter | null;
  groupId_Exact?: string | null;
  groupId_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  name_Exact?: string | null;
  name_In?: ReadonlyArray<string> | null;
  search?: string | null;
  team_In?: ReadonlyArray<UserTeamEnum> | null;
};
export type UserOrder = {
  email?: Ordering | null;
  id?: Ordering | null;
  name?: Ordering | null;
};
export type permission_usersQuery$variables = {
  filters?: UserFilter | null;
  order?: UserOrder | null;
};
export type permission_usersQuery$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserPermissionsUpdateDialog_user" | "UserUpdateDialog_user">;
      };
    }>;
    readonly totalCount: number | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UserPaginator_query">;
};
export type permission_usersQuery = {
  response: permission_usersQuery$data;
  variables: permission_usersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "permission_usersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserPermissionsUpdateDialog_user"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserUpdateDialog_user"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UserPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "permission_usersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "picture",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Group",
                    "kind": "LinkedField",
                    "name": "group",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slackId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "joinedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeleted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "UserPaginator_query_users",
        "kind": "LinkedHandle",
        "name": "users"
      }
    ]
  },
  "params": {
    "cacheID": "f23c1dc4182a9343098a87306dfa8778",
    "id": null,
    "metadata": {},
    "name": "permission_usersQuery",
    "operationKind": "query",
    "text": "query permission_usersQuery(\n  $filters: UserFilter\n  $order: UserOrder\n) {\n  users(filters: $filters, order: $order) {\n    totalCount\n    edges {\n      node {\n        id\n        ...UserPermissionsUpdateDialog_user\n        ...UserUpdateDialog_user\n      }\n    }\n  }\n  ...UserPaginator_query_2V3Dlp\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment UserConnectionDataTable_UserConnection on UserConnection {\n  edges {\n    node {\n      id\n      ...UserAvatarText_user\n      group {\n        id\n        title\n      }\n      email\n      slackId\n      joinedAt\n    }\n  }\n}\n\nfragment UserPaginator_query_2V3Dlp on Query {\n  users(filters: $filters, order: $order) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        name\n        group {\n          id\n          title\n        }\n        ...UserAvatarText_user\n        ...UserPermissionsUpdateDialog_user\n        __typename\n      }\n    }\n    ...UserConnectionDataTable_UserConnection\n  }\n}\n\nfragment UserPermissionsUpdateDialog_user on User {\n  id\n  name\n  picture\n  permissions {\n    id\n    title\n  }\n  group {\n    id\n  }\n}\n\nfragment UserUpdateDialog_user on User {\n  id\n  slackId\n  joinedAt\n  ...UserAvatarText_user\n}\n"
  }
};
})();

(node as any).hash = "06822e2065d974a0d954a47d173541d8";

export default node;
