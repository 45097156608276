import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  TaskStatusEnum,
  TaskStatusTabList_taskStatusCountsQuery,
} from '../../../relay/__generated__/TaskStatusTabList_taskStatusCountsQuery.graphql';
import EnumPair from '../../core/EnumPair';
import HorizontalDivider from '../../core/HorizontalDivider';
import TabList, { TabListProps } from '../../core/TabList';
import Text from '../../core/Text';

const taskStatusCountsForTaskStatusTabList = graphql`
  query TaskStatusTabList_taskStatusCountsQuery {
    taskStatusCounts {
      count
      status
    }
  }
`;

type Props = { selectedId?: string } & Omit<
  TabListProps<{ id: string | number; value?: TaskStatusEnum; counter?: number }>,
  'items' | 'renderItem' | 'selectedIndex'
>;

const TaskStatusTabList = ({ selectedId = 'all', onSelect, ...props }: Props) => {
  const [{ taskStatusCounts }, refresh] = useLazyLoadQuery<TaskStatusTabList_taskStatusCountsQuery>(
    taskStatusCountsForTaskStatusTabList,
    {},
  );
  const totalCount = taskStatusCounts.reduce((acc, { count }) => acc + count, 0);

  const tabItems: { id: string; value?: TaskStatusEnum; counter?: number }[] = [
    { id: 'all', value: undefined, counter: totalCount },
    ...(
      [
        'problem_typing',
        'problem_creation',
        'image_creation',
        'first_labeling',
        'second_labeling',
        'review',
        'error_report',
        'published',
        'deactivated',
      ] as TaskStatusEnum[]
    ).map((taskStatus) => ({
      id: taskStatus,
      value: taskStatus,
      counter: taskStatusCounts.find(({ status }) => status === taskStatus)?.count || 0,
    })),
  ];

  return (
    <>
      <TabList
        items={tabItems}
        renderItem={({ id, value, counter }) => (
          <Text sx={{ fontSize: 1, whiteSpace: 'nowrap' }}>
            {id === 'all' ? '전체' : <EnumPair typename={'TaskStatusEnum'}>{value || ''}</EnumPair>}({counter})
          </Text>
        )}
        selectedIndex={tabItems.findIndex(({ id }) => selectedId === id)}
        onSelect={(...args) => {
          onSelect?.(...args);
          refresh();
        }}
        {...props}
      />
      <HorizontalDivider />
    </>
  );
};

export default TaskStatusTabList;
