/**
 * @generated SignedSource<<acf010898455c970556db4ec53ad89d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QuizAnswerTypeEnum = "O" | "X" | "%future added value";
export type QuizStatusEnum = "error_report" | "published" | "review" | "%future added value";
export type QuizFilter = {
  AND?: QuizFilter | null;
  NOT?: QuizFilter | null;
  OR?: QuizFilter | null;
  answer_Exact?: string | null;
  answer_In?: ReadonlyArray<string> | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  status_Exact?: QuizStatusEnum | null;
  status_In?: ReadonlyArray<QuizStatusEnum> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitDId_In?: ReadonlyArray<string> | null;
  unitDTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitTitle_Contains?: string | null;
};
export type QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery$variables = {
  filters?: QuizFilter | null;
  first?: number | null;
};
export type QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery$data = {
  readonly quizzes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly answer: QuizAnswerTypeEnum;
        readonly id: string;
        readonly problem: string;
        readonly unitD: {
          readonly id: string;
          readonly title: string;
        };
      };
    }>;
  };
};
export type QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery = {
  response: QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery$data;
  variables: QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      }
    ],
    "concreteType": "QuizConnection",
    "kind": "LinkedField",
    "name": "quizzes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "QuizEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Quiz",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "unitD",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problem",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5a4d11f1584996ddeaefb540b1da235d",
    "id": null,
    "metadata": {},
    "name": "QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery",
    "operationKind": "query",
    "text": "query QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery(\n  $filters: QuizFilter\n  $first: Int\n) {\n  quizzes(filters: $filters, first: $first) {\n    edges {\n      node {\n        id\n        unitD {\n          id\n          title\n        }\n        answer\n        problem\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c313cf2b9a3342afe3b7cebe99f72e5";

export default node;
