import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitSelectField_unitsQuery,
  UnitSelectField_unitsQuery$variables,
} from '../../../relay/__generated__/UnitSelectField_unitsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';
const unitsForUnitSelectField = graphql`
  query UnitSelectField_unitsQuery($filters: UnitFilter, $order: UnitOrder) {
    units(filters: $filters, order: $order) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = { variables?: UnitSelectField_unitsQuery$variables } & Omit<SelectFieldProps, 'options'>;

const UnitSelectField = ({ variables, ...props }: Props) => {
  const [{ units }] = useLazyLoadQuery<UnitSelectField_unitsQuery>(unitsForUnitSelectField, { ...variables });
  return <SelectField options={units.edges.map(({ node: { id, title } }) => ({ id, text: title }))} {...props} />;
};

export default UnitSelectField;
