/**
 * @generated SignedSource<<b45ce1f7a19f5378f011fffba6e16eca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScrapItem_scrap$data = {
  readonly id: string;
  readonly problemBookPage: number;
  readonly problemNumber: number;
  readonly solutionBookPage: number;
  readonly " $fragmentType": "ScrapItem_scrap";
};
export type ScrapItem_scrap$key = {
  readonly " $data"?: ScrapItem_scrap$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapItem_scrap">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapItem_scrap",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problemBookPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solutionBookPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problemNumber",
      "storageKey": null
    }
  ],
  "type": "Scrap",
  "abstractKey": null
};

(node as any).hash = "b519bb9fd64149b9d2f2999bef2806cc";

export default node;
