/**
 * @generated SignedSource<<9b83db0967d115948d4afc924aced404>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
export type TaskStatusTabList_taskStatusCountsQuery$variables = {};
export type TaskStatusTabList_taskStatusCountsQuery$data = {
  readonly taskStatusCounts: ReadonlyArray<{
    readonly count: number;
    readonly status: TaskStatusEnum;
  }>;
};
export type TaskStatusTabList_taskStatusCountsQuery = {
  response: TaskStatusTabList_taskStatusCountsQuery$data;
  variables: TaskStatusTabList_taskStatusCountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskStatusCount",
    "kind": "LinkedField",
    "name": "taskStatusCounts",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskStatusTabList_taskStatusCountsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TaskStatusTabList_taskStatusCountsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "3c2420a94196d344724711b363e11d51",
    "id": null,
    "metadata": {},
    "name": "TaskStatusTabList_taskStatusCountsQuery",
    "operationKind": "query",
    "text": "query TaskStatusTabList_taskStatusCountsQuery {\n  taskStatusCounts {\n    count\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ddf7d0cc4e167dbf17be1a93c38b49d";

export default node;
