/**
 * @generated SignedSource<<b3e9c78c5e37eaff06d77957597f5609>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceExamCategoryEnum = "common" | "giha" | "hwak_tong" | "mijukbun" | "none" | "type_ga" | "type_na" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamSourceEnum = "evaluation_test" | "military_academy" | "mock_test" | "police_academy" | "sat" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type ScrapSourceTypeEnum = "book" | "exam" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceDescriptionList_scrapSource$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly examInfo: {
    readonly category: ScrapSourceExamCategoryEnum;
    readonly grade: ScrapSourceExamGradeEnum;
    readonly month: ScrapSourceExamMonthEnum;
    readonly source: ScrapSourceExamSourceEnum;
    readonly year: ScrapSourceExamYearEnum;
  } | null;
  readonly id: string;
  readonly nonExamInfo: {
    readonly bookName: string;
    readonly bookPublisher: string;
    readonly unitA: {
      readonly id: string;
      readonly title: string;
    };
  } | null;
  readonly submittedBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  } | null;
  readonly type: ScrapSourceTypeEnum;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceStatusLabel_scrapSource">;
  readonly " $fragmentType": "ScrapSourceDescriptionList_scrapSource";
};
export type ScrapSourceDescriptionList_scrapSource$key = {
  readonly " $data"?: ScrapSourceDescriptionList_scrapSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceDescriptionList_scrapSource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapSourceDescriptionList_scrapSource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScrapSourceNonExamInfo",
      "kind": "LinkedField",
      "name": "nonExamInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bookName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UnitA",
          "kind": "LinkedField",
          "name": "unitA",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bookPublisher",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScrapSourceExamInfo",
      "kind": "LinkedField",
      "name": "examInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "month",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "source",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScrapSourceStatusLabel_scrapSource"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "submittedBy",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "ScrapSource",
  "abstractKey": null
};
})();

(node as any).hash = "76b9d9b5adaa4da7547e02e831136eac";

export default node;
