/**
 * @generated SignedSource<<97c3bffdf73f302b9ad7a0f5e6f4bd5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitBFilter = {
  AND?: UnitBFilter | null;
  NOT?: UnitBFilter | null;
  OR?: UnitBFilter | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitBOrder = {
  id?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type UnitBPaginatorSearchOverlayTokenField_unitBsQuery$variables = {
  after?: string | null;
  filters?: UnitBFilter | null;
  first?: number | null;
  order?: UnitBOrder | null;
};
export type UnitBPaginatorSearchOverlayTokenField_unitBsQuery$data = {
  readonly unitBs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"UnitBPaginator_query">;
};
export type UnitBPaginatorSearchOverlayTokenField_unitBsQuery = {
  response: UnitBPaginatorSearchOverlayTokenField_unitBsQuery$data;
  variables: UnitBPaginatorSearchOverlayTokenField_unitBsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitBPaginatorSearchOverlayTokenField_unitBsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnitBConnection",
        "kind": "LinkedField",
        "name": "unitBs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitBEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitB",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "UnitBPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnitBPaginatorSearchOverlayTokenField_unitBsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnitBConnection",
        "kind": "LinkedField",
        "name": "unitBs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitBEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitB",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "UnitBPaginator_query_unitBs",
        "kind": "LinkedHandle",
        "name": "unitBs"
      }
    ]
  },
  "params": {
    "cacheID": "75a9b5c4eb00c47a5c1a08a43f4327cb",
    "id": null,
    "metadata": {},
    "name": "UnitBPaginatorSearchOverlayTokenField_unitBsQuery",
    "operationKind": "query",
    "text": "query UnitBPaginatorSearchOverlayTokenField_unitBsQuery(\n  $filters: UnitBFilter\n  $order: UnitBOrder\n  $first: Int\n  $after: String\n) {\n  unitBs(filters: $filters, order: $order, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n  ...UnitBPaginator_query_2rfEMg\n}\n\nfragment UnitBPaginator_query_2rfEMg on Query {\n  unitBs(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        title\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6514c8ce0d5dc9e3a166fdeef1fa6be3";

export default node;
