import { TextInputWithTokens, TextInputWithTokensProps } from '@primer/react';
import { FieldConfig, useField } from 'formik';
import { ComponentProps, Suspense, useEffect, useRef } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import useFormikContext from '../../../hooks/useFormikContext';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { UnitDSelectDialogTokenField_unitAsQuery } from '../../../relay/__generated__/UnitDSelectDialogTokenField_unitAsQuery.graphql';
import {
  UnitDSelectDialogTokenField_unitDsQuery,
  UnitDSelectDialogTokenField_unitDsQuery$variables,
} from '../../../relay/__generated__/UnitDSelectDialogTokenField_unitDsQuery.graphql';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import Checkbox from '../../core/Checkbox';
import Dialog from '../../core/Dialog';
import DialogHandler from '../../core/DialogHandler';
import FormControl from '../../core/FormControl';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import QueryFormik from '../../core/QueryFormik';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import Truncate from '../../core/Truncate';
import View from '../../core/View';
import UnitARadioGroupField from '../../unitA/UnitARadioGroupField';
import UnitDConnectionDataTable from '../UnitDConnectionDataTable';

const unitDsForUnitDSelectDialogTokenField = graphql`
  query UnitDSelectDialogTokenField_unitDsQuery($filters: UnitDFilter, $order: UnitDOrder) {
    unitDs(filters: $filters, order: $order) {
      edges {
        node {
          id
          title: description
        }
      }
      ...UnitDConnectionDataTable_unitDConnection
    }
  }
`;

const unitAsForUnitDSelectDialogTokenField = graphql`
  query UnitDSelectDialogTokenField_unitAsQuery($filters: UnitAFilter, $order: UnitAOrder) {
    unitAs(filters: $filters, order: $order, first: 1) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  value?: string[];
  onChange?: (id: string) => void;
  caption?: string;
  variables?: {
    filters?: UnitDSelectDialogTokenField_unitDsQuery$variables['filters'];
    // order는 일부러 생략합니다. 테이블의 각 unit을 머지하는 과정에서 unitD의 정렬을 바꾸면, 같은 unitB, unitC끼리 묶을 수가 없습니다.
  };
} & Pick<TextInputWithTokensProps, 'placeholder' | 'disabled' | 'name' | 'required'> &
  Pick<FieldConfig, 'validate'>;

const UnitDSelectDialogTokenField = ({
  label,
  labelConfig,
  value: propValue,
  onChange: propOnChange,
  name = '',
  placeholder,
  disabled: propDisabled,
  required,
  caption,
  // TODO: variables 반영을 조금 더 신경써야하는데 머리가 너무 아파서 나중에 하겠습니다.
  variables,
  validate,
}: Props) => {
  const inputId = `unitD_select_dialog_token_field_for_${label}`;
  const textInputRef = useRef<HTMLInputElement>(null);

  const [{ value: baseValue = [] }, { error }, { setValue, setError }] = useField<string[]>({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (value ?? []).length === 0) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });

  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;
  const handleChange = (selected: string) => {
    setError(undefined);

    propOnChange
      ? propOnChange(selected)
      : setValue(value.includes(selected) ? value.filter((v) => v !== selected) : [...value, selected]);
  };
  const handleTokenRemove: TextInputWithTokensProps['onTokenRemove'] = (tokenId) => {
    handleChange(tokenId as string);
  };

  const [valueQueryReference, loadValueQuery, disposeValueQuery] =
    useQueryLoader<UnitDSelectDialogTokenField_unitDsQuery>(unitDsForUnitDSelectDialogTokenField);
  useEffect(() => {
    loadValueQuery({ filters: { id_In: value || [] } });
    return () => disposeValueQuery();
  }, [JSON.stringify(baseValue), loadValueQuery, disposeValueQuery]);

  const [{ unitAs }] = useLazyLoadQuery<UnitDSelectDialogTokenField_unitAsQuery>(unitAsForUnitDSelectDialogTokenField, {
    filters: {
      unitSchoolType_Exact: variables?.filters?.unitSchoolType_Exact,
      unitSchoolType_In: variables?.filters?.unitSchoolType_In,
    },
    order: { order: 'ASC' },
  });
  const { edges } = unitAs;
  const [
    {
      node: { id: firstUnitAId },
    },
  ] = edges;

  const disabled = propDisabled || isSubmitting;

  return valueQueryReference ? (
    <PreloadedQueryRenderer query={unitDsForUnitDSelectDialogTokenField} queryReference={valueQueryReference}>
      {({ unitDs }) => (
        <FormControl required={required} disabled={disabled}>
          <FormControl.Label {...labelConfig}>{label}</FormControl.Label>
          <DialogHandler
            renderDialog={({ closeDialog, isOpen }) => (
              <Dialog
                isOpen={isOpen}
                onDismiss={() => {
                  closeDialog();
                }}
                wide
              >
                <Dialog.Header>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>Unit D 선택하기</Text>
                </Dialog.Header>
                <Dialog.Body>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik<UnitDSelectDialogTokenField_unitDsQuery>
                      query={unitDsForUnitDSelectDialogTokenField}
                      initialValues={{ filters: { unitAId_In: [firstUnitAId] }, order: 'order' }}
                    >
                      {({ setFieldValue, handleSubmit }) => (
                        <FormLayout>
                          <Suspense>
                            <UnitARadioGroupField
                              name={'filters.unitAId_In[0]'}
                              label={'Unit A'}
                              required
                              onChange={(selected) => {
                                setFieldValue('filters.unitAId_In[0]', selected);
                                handleSubmit();
                              }}
                              variables={{ filters: variables?.filters, order: { order: 'ASC' } }}
                              renderContainer={(children) => (
                                <Grid gapX={2} gapY={2}>
                                  {children}
                                </Grid>
                              )}
                              renderOptionWrapper={(children, { id }) => (
                                <Grid.Unit key={id} size={'min'}>
                                  {children}
                                </Grid.Unit>
                              )}
                            />
                          </Suspense>
                          <Suspense fallback={<Spinner />}>
                            <QueryFormik.PreloadedQueryRenderer<UnitDSelectDialogTokenField_unitDsQuery>>
                              {({ unitDs }) => (
                                <UnitDConnectionDataTable
                                  unitDConnection={unitDs}
                                  renderRow={(row, columns, rowIndex, rows) =>
                                    columns.map(({ field, renderValue, width, align = 'start' }) => {
                                      const unitBRowSpan = rows.filter(
                                        ({ unitBTitle }) => unitBTitle === row.unitBTitle,
                                      ).length;
                                      const unitCRowSpan = rows.filter(
                                        ({ unitCTitle }) => unitCTitle === row.unitCTitle,
                                      ).length;
                                      const isUnitBSpanned =
                                        rowIndex > 0 ? rows[rowIndex - 1].unitBTitle === row.unitBTitle : false;
                                      const isUnitCSpanned =
                                        rowIndex > 0 ? rows[rowIndex - 1].unitCTitle === row.unitCTitle : false;

                                      return field === 'unitB' ? (
                                        !isUnitBSpanned ? (
                                          <View
                                            key={field}
                                            as={'td'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: width,
                                              padding: 2,
                                              textAlign: align,
                                            }}
                                            rowSpan={unitBRowSpan}
                                          >
                                            {renderValue(row, rowIndex)}
                                          </View>
                                        ) : null
                                      ) : field === 'unitC' ? (
                                        !isUnitCSpanned ? (
                                          <View
                                            key={field}
                                            as={'td'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: width,
                                              padding: 2,
                                              textAlign: align,
                                            }}
                                            rowSpan={unitCRowSpan}
                                          >
                                            {renderValue(row, rowIndex)}
                                          </View>
                                        ) : null
                                      ) : field === 'unitD' ? (
                                        <View
                                          key={field}
                                          as={'td'}
                                          sx={{
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'border.default',
                                            minWidth: width,
                                            padding: 2,
                                            textAlign: align,
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => handleChange(row.id)}
                                        >
                                          <Grid gapX={1} wrap={false} sx={{ alignItems: 'center' }}>
                                            <Grid.Unit size={'min'}>
                                              <Checkbox checked={value.includes(row.id)} />
                                            </Grid.Unit>
                                            <Grid.Unit size={'max'}>{renderValue(row, rowIndex)}</Grid.Unit>
                                          </Grid>
                                        </View>
                                      ) : null;
                                    })
                                  }
                                />
                              )}
                            </QueryFormik.PreloadedQueryRenderer>
                          </Suspense>
                        </FormLayout>
                      )}
                    </QueryFormik>
                  </Suspense>
                </Dialog.Body>
              </Dialog>
            )}
          >
            {({ openDialog }) => (
              <TextInputWithTokens
                ref={textInputRef}
                tokens={value.map((id) => {
                  const tokenText = unitDs?.edges.find(({ node }) => node.id === id)?.node.title || '';
                  return {
                    id,
                    text: (
                      <Truncate
                        title={tokenText}
                        maxWidth={300}
                        onClick={() => {
                          textInputRef.current?.focus();
                        }}
                      >
                        {tokenText}
                      </Truncate>
                    ),
                  };
                })}
                onTokenRemove={disabled ? () => {} : handleTokenRemove}
                id={inputId}
                size={'medium'}
                block
                placeholder={value.length > 0 ? '' : placeholder}
                disabled={disabled}
                autoComplete={'off'}
                visibleTokenCount={2}
                preventTokenWrapping
                onFocus={() => openDialog()}
                {...(error ? { validationStatus: 'error' } : {})}
              />
            )}
          </DialogHandler>
          {error ? (
            <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
          ) : caption ? (
            <FormControl.Caption>{caption}</FormControl.Caption>
          ) : null}
        </FormControl>
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default UnitDSelectDialogTokenField;
