/**
 * @generated SignedSource<<42c4e6b2a39171ffc597176d1855fa6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type ScrapSourceExamCategoryEnum = "common" | "giha" | "hwak_tong" | "mijukbun" | "none" | "type_ga" | "type_na" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamSourceEnum = "evaluation_test" | "military_academy" | "mock_test" | "police_academy" | "sat" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type TaskErrorTypeEnum = "hint" | "problem" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type VideoTypeEnum = "horizontal" | "vertical" | "%future added value";
export type TaskFilter = {
  AND?: TaskFilter | null;
  NOT?: TaskFilter | null;
  OR?: TaskFilter | null;
  aiDifficulty_Gte?: number | null;
  aiDifficulty_Lte?: number | null;
  answerType_Exact?: TaskProblemAnswerTypeEnum | null;
  answerType_In?: ReadonlyArray<TaskProblemAnswerTypeEnum> | null;
  assignedToId_Exact?: string | null;
  assignedToId_In?: ReadonlyArray<string> | null;
  assignedTo_Exists?: boolean | null;
  assignedTo_Exists_In?: ReadonlyArray<boolean> | null;
  completedById_Exclude_In?: ReadonlyArray<string> | null;
  completedById_In?: ReadonlyArray<string> | null;
  completed_Gte?: any | null;
  completed_Lte?: any | null;
  correctResultFeedback_Published_In?: ReadonlyArray<boolean> | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  difficulty_Gte?: number | null;
  difficulty_Lte?: number | null;
  ebook_In?: ReadonlyArray<string> | null;
  errorType_In?: ReadonlyArray<TaskErrorTypeEnum> | null;
  generalSubmitFeedback_Published_In?: ReadonlyArray<boolean> | null;
  hint_Published_In?: ReadonlyArray<boolean> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  image_Exists_In?: ReadonlyArray<boolean> | null;
  incorrectResultFeedback_Published_In?: ReadonlyArray<boolean> | null;
  lastUnitDId_Exact?: string | null;
  lastUnitDId_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  problemCreationType_Exact?: TaskProblemCreationTypeEnum | null;
  problemCreationType_In?: ReadonlyArray<TaskProblemCreationTypeEnum> | null;
  problemGenerator_Exists_In?: ReadonlyArray<boolean> | null;
  problemType_Exact?: TaskProblemTypeEnum | null;
  problemType_In?: ReadonlyArray<TaskProblemTypeEnum> | null;
  scrapSourceBookName_Contains?: string | null;
  scrapSourceBookPublisher_Contains?: string | null;
  scrapSourceCategory_Exact?: ScrapSourceExamCategoryEnum | null;
  scrapSourceCategory_In?: ReadonlyArray<ScrapSourceExamCategoryEnum> | null;
  scrapSourceGrade_Exact?: ScrapSourceExamGradeEnum | null;
  scrapSourceGrade_In?: ReadonlyArray<ScrapSourceExamGradeEnum> | null;
  scrapSourceMonth_Exact?: ScrapSourceExamMonthEnum | null;
  scrapSourceMonth_In?: ReadonlyArray<ScrapSourceExamMonthEnum> | null;
  scrapSourceSource_Exact?: ScrapSourceExamSourceEnum | null;
  scrapSourceSource_In?: ReadonlyArray<ScrapSourceExamSourceEnum> | null;
  scrapSourceUnitAId_Exact?: string | null;
  scrapSourceUnitAId_In?: ReadonlyArray<string> | null;
  scrapSourceYear_Exact?: ScrapSourceExamYearEnum | null;
  scrapSourceYear_In?: ReadonlyArray<ScrapSourceExamYearEnum> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  solutionVideoCreatedById_Exact?: string | null;
  solutionVideoCreatedById_In?: ReadonlyArray<string> | null;
  solutionVideoTeacherId_Exact?: string | null;
  solutionVideoTeacherId_In?: ReadonlyArray<string> | null;
  solutionVideoTeacherName_Exact?: string | null;
  solutionVideoTeacherName_In?: ReadonlyArray<string> | null;
  solutionVideoVideoType_Exact?: VideoTypeEnum | null;
  solutionVideoVideoType_In?: ReadonlyArray<VideoTypeEnum> | null;
  solutionVideo_Exists?: boolean | null;
  solutionVideo_Exists_In?: ReadonlyArray<boolean> | null;
  specialSubmitFeedback_Published_In?: ReadonlyArray<boolean> | null;
  status_Exact?: TaskStatusEnum | null;
  status_In?: ReadonlyArray<TaskStatusEnum> | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  typingAutomationScript_Exists_In?: ReadonlyArray<boolean> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitBId_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitCId_Exact?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitDId_Exact?: string | null;
  unitDId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
};
export type TaskOrder = {
  assigned?: Ordering | null;
  completed?: Ordering | null;
  created?: Ordering | null;
  description?: Ordering | null;
  difficulty?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  sequence?: Ordering | null;
  title?: Ordering | null;
  unitD_Order?: Ordering | null;
};
export type WorkbookTasksAddDialog_tasksQuery$variables = {
  after?: string | null;
  filters?: TaskFilter | null;
  first?: number | null;
  order?: TaskOrder | null;
};
export type WorkbookTasksAddDialog_tasksQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaskPaginator_query">;
};
export type WorkbookTasksAddDialog_tasksQuery = {
  response: WorkbookTasksAddDialog_tasksQuery$data;
  variables: WorkbookTasksAddDialog_tasksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookTasksAddDialog_tasksQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "TaskPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkbookTasksAddDialog_tasksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "TaskConnection",
        "kind": "LinkedField",
        "name": "tasks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "TaskPaginator_query_tasks",
        "kind": "LinkedHandle",
        "name": "tasks"
      }
    ]
  },
  "params": {
    "cacheID": "f281a6fd3d20f932512bbfb7c2a7d3a0",
    "id": null,
    "metadata": {},
    "name": "WorkbookTasksAddDialog_tasksQuery",
    "operationKind": "query",
    "text": "query WorkbookTasksAddDialog_tasksQuery(\n  $filters: TaskFilter\n  $order: TaskOrder\n  $first: Int\n  $after: String\n) {\n  ...TaskPaginator_query_2rfEMg\n}\n\nfragment TaskPaginator_query_2rfEMg on Query {\n  tasks(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        ...TaskSequenceItem_task\n        __typename\n      }\n    }\n  }\n}\n\nfragment TaskSequenceItem_task on Task {\n  sequence\n}\n"
  }
};
})();

(node as any).hash = "277e8577edc24bd3dcfbee1c1c386742";

export default node;
