/**
 * @generated SignedSource<<09cd07194cbf0412eb4e71afc44c7733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type WorkbookStatusEnum = "created" | "deactivated" | "publish_required" | "published" | "%future added value";
export type WorkbookFilter = {
  AND?: WorkbookFilter | null;
  NOT?: WorkbookFilter | null;
  OR?: WorkbookFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  grade_In?: ReadonlyArray<ScrapSourceExamGradeEnum> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  month_In?: ReadonlyArray<ScrapSourceExamMonthEnum> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  sourceName_In?: ReadonlyArray<string> | null;
  status_In?: ReadonlyArray<WorkbookStatusEnum> | null;
  taskCount_Gte?: number | null;
  taskCount_Lte?: number | null;
  taskUnitAId_In?: ReadonlyArray<string> | null;
  taskUnitBId_In?: ReadonlyArray<string> | null;
  task_In?: ReadonlyArray<string> | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  year_In?: ReadonlyArray<ScrapSourceExamYearEnum> | null;
};
export type WorkbookOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  sequence?: Ordering | null;
  title?: Ordering | null;
};
export type WorkbookWindowPaginatorRefreshQuery$variables = {
  filters?: WorkbookFilter | null;
  order?: WorkbookOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type WorkbookWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookWindowPaginator_query">;
};
export type WorkbookWindowPaginatorRefreshQuery = {
  response: WorkbookWindowPaginatorRefreshQuery$data;
  variables: WorkbookWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "WorkbookWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "WorkbookConnection",
        "kind": "LinkedField",
        "name": "workbooks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkbookEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Workbook",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "year",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "month",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sourceName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "modifiedBy",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "picture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8369c9db6a0cdc043edc9c5f44730e40",
    "id": null,
    "metadata": {},
    "name": "WorkbookWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query WorkbookWindowPaginatorRefreshQuery(\n  $filters: WorkbookFilter\n  $order: WorkbookOrder\n  $page: Int = 1\n  $pageSize: Int\n) {\n  ...WorkbookWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment WorkbookConnectionDataTable_workbookConnection on WorkbookConnection {\n  edges {\n    node {\n      id\n      sequence\n      ...WorkbookStatusLabel_workbook\n      title\n      year\n      grade\n      month\n      sourceName\n      modified\n      modifiedBy {\n        id\n        ...UserAvatarText_user\n      }\n    }\n  }\n}\n\nfragment WorkbookStatusLabel_workbook on Workbook {\n  status\n}\n\nfragment WorkbookWindowPaginator_query_36liK3 on Query {\n  workbooks(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...WorkbookConnectionDataTable_workbookConnection\n  }\n}\n"
  }
};
})();

(node as any).hash = "aac33b0c89450bb473dbd96b020752d5";

export default node;
