/**
 * @generated SignedSource<<b283b8da4b54d76a31d33f070cd69523>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskLabelingCountEnum = "one" | "two" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskProblemUpdateInput = {
  answer?: string | null;
  answerChoiceCount?: number | null;
  answerType?: TaskProblemAnswerTypeEnum | null;
  creationType?: TaskProblemCreationTypeEnum | null;
  imageRequests: ReadonlyArray<ImageRequestInput>;
  labelingCount?: TaskLabelingCountEnum | null;
  problem?: EditorInput | null;
  solution?: EditorInput | null;
  task: string;
  taskProblem: string;
  unitDs: ReadonlyArray<string>;
};
export type ImageRequestInput = {
  description: string;
  file: Upload;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type EditorInput = {
  text: string;
  textHtml?: string | null;
};
export type TaskProblemUpdateDialog_taskProblemUpdateMutation$variables = {
  input: TaskProblemUpdateInput;
};
export type TaskProblemUpdateDialog_taskProblemUpdateMutation$data = {
  readonly taskProblemUpdate: {
    readonly answer: string | null;
    readonly answerChoiceCount: number | null;
    readonly answerType: TaskProblemAnswerTypeEnum | null;
    readonly creationType: TaskProblemCreationTypeEnum | null;
    readonly id: string;
    readonly problem: string | null;
    readonly solution: string | null;
  };
};
export type TaskProblemUpdateDialog_taskProblemUpdateMutation = {
  response: TaskProblemUpdateDialog_taskProblemUpdateMutation$data;
  variables: TaskProblemUpdateDialog_taskProblemUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TaskProblem",
    "kind": "LinkedField",
    "name": "taskProblemUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problem",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "solution",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "answerType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "answer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "answerChoiceCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "creationType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskProblemUpdateDialog_taskProblemUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskProblemUpdateDialog_taskProblemUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c8dfbbac0b5c04bebf0b7b5ff487258",
    "id": null,
    "metadata": {},
    "name": "TaskProblemUpdateDialog_taskProblemUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskProblemUpdateDialog_taskProblemUpdateMutation(\n  $input: TaskProblemUpdateInput!\n) {\n  taskProblemUpdate(input: $input) {\n    id\n    problem\n    solution\n    answerType\n    answer\n    answerChoiceCount\n    creationType\n  }\n}\n"
  }
};
})();

(node as any).hash = "ab2e355781683a90bf64678a90fed31e";

export default node;
