/**
 * @generated SignedSource<<56dc8238a8c04d3b2df3a60407c644b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Ordering = "ASC" | "DESC" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitDFilter = {
  AND?: UnitDFilter | null;
  NOT?: UnitDFilter | null;
  OR?: UnitDFilter | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: UnitSchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<UnitSchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitDOrder = {
  description?: Ordering | null;
  id?: Ordering | null;
  order?: Ordering | null;
  title?: Ordering | null;
};
export type UnitDSearchChoiceTextField_unitDsQuery$variables = {
  filters?: UnitDFilter | null;
  first?: number | null;
  order?: UnitDOrder | null;
};
export type UnitDSearchChoiceTextField_unitDsQuery$data = {
  readonly unitDs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly id: string;
        readonly unitATitle: string;
        readonly unitBTitle: string;
        readonly unitCTitle: string;
      };
    }>;
  };
};
export type UnitDSearchChoiceTextField_unitDsQuery = {
  response: UnitDSearchChoiceTextField_unitDsQuery$data;
  variables: UnitDSearchChoiceTextField_unitDsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "UnitDConnection",
    "kind": "LinkedField",
    "name": "unitDs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnitDEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitD",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unitATitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unitBTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unitCTitle",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitDSearchChoiceTextField_unitDsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnitDSearchChoiceTextField_unitDsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "19f8f54e3bb0e5343f30a1a7564610d2",
    "id": null,
    "metadata": {},
    "name": "UnitDSearchChoiceTextField_unitDsQuery",
    "operationKind": "query",
    "text": "query UnitDSearchChoiceTextField_unitDsQuery(\n  $filters: UnitDFilter\n  $order: UnitDOrder\n  $first: Int\n) {\n  unitDs(filters: $filters, order: $order, first: $first) {\n    edges {\n      node {\n        id\n        description\n        unitATitle\n        unitBTitle\n        unitCTitle\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6abd090c5037b02f0f0b862287c31520";

export default node;
