/**
 * @generated SignedSource<<9b283c428e7f623c8e311c5f75e35c02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATSchoolYearMajorTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1_common" | "high_school_2_common" | "high_school_3_giha" | "high_school_3_hwaktong" | "high_school_3_mijukbun" | "high_school_n_giha" | "high_school_n_hwaktong" | "high_school_n_mijukbun" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type MATOrganizationStudentsCreateInput = {
  count: number;
  organization: string;
  studentType: MATSchoolYearMajorTypeEnum;
};
export type MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation$variables = {
  input: MATOrganizationStudentsCreateInput;
};
export type MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation$data = {
  readonly organizationStudentsCreate: boolean;
};
export type MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation = {
  response: MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation$data;
  variables: MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "organizationStudentsCreate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "992f5d9b7c74290aba7e3b0e7d58bd0a",
    "id": null,
    "metadata": {},
    "name": "MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation",
    "operationKind": "mutation",
    "text": "mutation MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation(\n  $input: MATOrganizationStudentsCreateInput!\n) {\n  organizationStudentsCreate(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "14104e025d86c01df0ca1c1fee4cb2b0";

export default node;
