import { MailIcon, PencilIcon, PlusIcon, SignInIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { Suspense } from 'react';
import { graphql } from 'react-relay';

import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import DialogIconButton from '../../components/core/DialogIconButton';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmIconButton from '../../components/core/MutationConfirmIconButton';
import MutationIconButton from '../../components/core/MutationIconButton';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import MATInvoiceConnectionDataTable from '../../components/matInvoice/MATInvoiceConnectionDataTable';
import MATInvoiceUpdateDialog from '../../components/matInvoice/MATInvoiceUpdateDialog';
import MATOrganizationDescriptionList from '../../components/matOrganization/MATOrganizationDescriptionList';
import MATOrganizationInvoiceCreateDialog from '../../components/matOrganization/MATOrganizationInvoiceCreateDialog';
import MATOrganizationLMSSendDialog from '../../components/matOrganization/MATOrganizationLMSSendDialog';
import MATOrganizationStudentsCreateDialog from '../../components/matOrganization/MATOrganizationStudentsCreateDialog';
import MATOrganizationTeachersCreateDialog from '../../components/matOrganization/MATOrganizationTeachersCreateDialog';
import MATStudentConnectionDataTable from '../../components/matStudent/MATStudentConnectionDataTable';
import MATStudentWindowPaginator from '../../components/matStudent/MATStudentWindowPaginator';
import MATTeacherConnectionDataTable from '../../components/matTeacher/MATTeacherConnectionDataTable';
import MATTeacherWindowPaginator from '../../components/matTeacher/MATTeacherWindowPaginator';
import { useMeContext } from '../../contexts/MeContext';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { OrganizationId_invoiceDeleteMutation } from '../../relay/__generated__/OrganizationId_invoiceDeleteMutation.graphql';
import { OrganizationId_meQuery } from '../../relay/__generated__/OrganizationId_meQuery.graphql';
import { OrganizationId_organizationQuery } from '../../relay/__generated__/OrganizationId_organizationQuery.graphql';
import { OrganizationId_organizationStudentDeleteMutation } from '../../relay/__generated__/OrganizationId_organizationStudentDeleteMutation.graphql';
import { OrganizationId_organizationStudentsQuery } from '../../relay/__generated__/OrganizationId_organizationStudentsQuery.graphql';
import { OrganizationId_organizationTeachersQuery } from '../../relay/__generated__/OrganizationId_organizationTeachersQuery.graphql';
import { OrganizationId_superSignInMutation } from '../../relay/__generated__/OrganizationId_superSignInMutation.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

const organizationForOrganizationId = graphql`
  query OrganizationId_organizationQuery($id: ID!) {
    organization(id: $id) {
      id
      title
      lmsContent

      invoices {
        ...MATInvoiceConnectionDataTable_matInvoiceConnection
      }
      ...MATOrganizationDescriptionList_matOrganization
      ...MATOrganizationInvoiceCreateDialog_matOrganization
      ...MATOrganizationTeachersCreateDialog_matOrganization
      ...MATOrganizationStudentsCreateDialog_matOrganization
    }
  }
`;

const meForOrganizationId = graphql`
  query OrganizationId_meQuery {
    me {
      id
      lastLmsPrefix
    }
  }
`;

const organizationTeachersForOrganizationId = graphql`
  query OrganizationId_organizationTeachersQuery(
    $filters: MATTeacherFilter
    $order: MATTeacherOrder
    $page: Int
    $pageSize: Int
  ) {
    ...MATTeacherWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const organizationStudentsForOrganizationId = graphql`
  query OrganizationId_organizationStudentsQuery(
    $filters: MATStudentFilter
    $order: MATStudentOrder
    $page: Int
    $pageSize: Int
  ) {
    ...MATStudentWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const SAMPLE_ACCOUNT_PASSWORD = 'turing';

type Props = {};

const OrganizationId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { name: tcmsUserName } = useMeContext();

  const { organizationId } = router.query;
  const [data, refetch] = useLazyLoadQuery<OrganizationId_organizationQuery>(organizationForOrganizationId, {
    id: organizationId as string,
  });
  const [
    {
      me: { lastLmsPrefix },
    },
    refetchMe,
  ] = useLazyLoadQuery<OrganizationId_meQuery>(meForOrganizationId, {});

  const { organization } = data;
  if (!organization) return null;

  const { title, lmsContent } = organization;
  const content = lastLmsPrefix ? `${lastLmsPrefix}${lmsContent}` : lmsContent;

  return (
    <View>
      <Head siteTitle={`클래스 조직 관리 - ${title}`} />
      <Grid sx={{ alignItems: 'center' }}>
        <Grid.Unit size={'max'}>
          <Text as={'h1'}>{title}</Text>
        </Grid.Unit>
        <Grid.Unit size={'min'}>
          <DialogButton
            size={'large'}
            variant={'outline'}
            leadingIcon={MailIcon}
            renderDialog={({ isOpen, closeDialog }) => (
              <MATOrganizationLMSSendDialog
                initialValues={{ content }}
                isOpen={isOpen}
                onDismiss={closeDialog}
                config={{
                  onCompleted: () => {
                    toast('문자가 전송됐어요', 'success');
                    refetchMe();
                    closeDialog();
                  },
                  onError: () => {
                    toast('다시 전송해 주세요', 'error');
                  },
                }}
              />
            )}
          >
            문자 보내기
          </DialogButton>
        </Grid.Unit>
      </Grid>
      <HorizontalDivider mt={[3, 3, 0]} mb={5} />
      <Grid gapX={5} gapY={3}>
        <Grid.Unit size={3 / 4}>
          <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>조직 정보</Text>
          <Card mt={3} p={4}>
            <MATOrganizationDescriptionList
              matOrganization={organization}
              type={'default'}
              titleUnitSize={1 / 6}
              descriptionUnitSize={5 / 6}
            />
          </Card>
          <View mt={5}>
            <Grid gapX={3} sx={{ alignItems: 'center' }}>
              <Grid.Unit size={'max'}>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이용 정보</Text>
              </Grid.Unit>
              <Grid.Unit size={'min'}>
                <DialogButton
                  size={'large'}
                  variant={'primary'}
                  leadingIcon={PlusIcon}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <MATOrganizationInvoiceCreateDialog
                      matOrganization={organization}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          toast('결제 내역이 생성됐어요', 'success');
                          refetch();
                          closeDialog();
                        },
                        onError: () => {
                          toast('다시 생성해 주세요', 'error');
                        },
                      }}
                    />
                  )}
                >
                  생성하기
                </DialogButton>
              </Grid.Unit>
            </Grid>
            <View mt={3}>
              <MATInvoiceConnectionDataTable
                matInvoiceConnection={organization.invoices}
                emptyState={
                  <View sx={{ paddingY: 3 }}>
                    <EmptyState title={'이용 정보가 없어요'} />
                  </View>
                }
                renderHead={(columns) => {
                  const headCommonStyle = {
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'border.default',
                    textAlign: 'start',
                    padding: 2,
                    fontWeight: 'bold',
                    color: 'fg.muted',
                  };
                  return (
                    <View
                      as={'thead'}
                      sx={{
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderBottomColor: 'border.default',
                        backgroundColor: 'canvas.subtle',
                      }}
                    >
                      <View as={'tr'}>
                        {columns.map(({ field, title, width }) => (
                          <View
                            key={field}
                            as={'th'}
                            sx={{
                              minWidth: width,
                              ...headCommonStyle,
                            }}
                          >
                            {title}
                          </View>
                        ))}
                        <View as={'th'} sx={{ minWidth: 44, ...headCommonStyle }}>
                          수정
                        </View>
                        <View as={'th'} sx={{ minWidth: 44, ...headCommonStyle }}>
                          삭제
                        </View>
                      </View>
                    </View>
                  );
                }}
                renderRow={(row, columns, index) => (
                  <>
                    {columns.map(({ field, renderValue, width, align = 'start' }) => (
                      <View
                        key={field}
                        as={'td'}
                        sx={{
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderColor: 'border.default',
                          minWidth: width,
                          textAlign: align,
                          padding: 2,
                        }}
                      >
                        {renderValue(row, index)}
                      </View>
                    ))}
                    <View
                      as={'td'}
                      sx={{
                        paddingX: '12px',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'border.default',
                        textAlign: 'center',
                      }}
                    >
                      <DialogIconButton
                        aria-labelledby={'이용 정보 수정하기'}
                        size={'small'}
                        icon={PencilIcon}
                        variant={'default'}
                        renderDialog={({ isOpen, closeDialog }) => (
                          <MATInvoiceUpdateDialog
                            initialValues={{
                              id: row.id,
                              title: row.title,
                              premiumType: row.premiumType,
                              scholarshipAmount: row.scholarshipAmount,
                              startDate: row.startDate,
                              endDate: row.endDate,
                              maxStudentCount: row.maxStudentCount,
                              amount: row.amount,
                            }}
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            config={{
                              onCompleted: () => {
                                closeDialog();
                              },
                            }}
                          />
                        )}
                      />
                    </View>
                    <View
                      as={'td'}
                      sx={{
                        paddingX: '12px',
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'border.default',
                        textAlign: 'center',
                      }}
                    >
                      <MutationConfirmIconButton<OrganizationId_invoiceDeleteMutation>
                        aria-labelledby={'이용 정보 삭제하기'}
                        icon={TrashIcon}
                        size={'small'}
                        variant={'default'}
                        mutation={graphql`
                          mutation OrganizationId_invoiceDeleteMutation($input: MATInvoiceDeleteInput!) {
                            invoiceDelete(input: $input) {
                              id @deleteRecord
                              organization {
                                id
                                currentInvoice {
                                  id
                                }
                              }
                            }
                          }
                        `}
                        input={{ id: row.id }}
                        message={'이용 정보를 삭제할까요?'}
                        config={{
                          onCompleted: () => {
                            toast('삭제가 완료됐어요', 'success');
                          },
                        }}
                      />
                    </View>
                  </>
                )}
              />
            </View>
          </View>
          <QueryFormik<OrganizationId_organizationTeachersQuery>
            query={organizationTeachersForOrganizationId}
            staticVariables={{ filters: { organization_Exact: organizationId as string }, pageSize: 20 }}
            initialValues={{ page: 1 }}
            options={{ fetchPolicy: 'store-and-network' }}
            enableReinitialize
          >
            {({ submitForm }, queryReference) => (
              <View mt={5}>
                <Grid gapX={3} sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>선생님 계정</Text>
                    <View sx={{ mt: '2px' }}>
                      <Text fontSize={0} color={'fg.subtle'}>
                        샘플 계정 비밀번호는 모두 {SAMPLE_ACCOUNT_PASSWORD}으로 만들어져요
                      </Text>
                    </View>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <DialogButton
                      size={'large'}
                      variant={'primary'}
                      leadingIcon={PlusIcon}
                      renderDialog={({ isOpen, closeDialog }) => (
                        <MATOrganizationTeachersCreateDialog
                          matOrganization={organization}
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          config={{
                            onCompleted: () => {
                              toast('선생님 계정이 생성됐어요', 'success');
                              submitForm();
                              refetch();
                              closeDialog();
                            },
                            onError: () => {
                              toast('다시 생성해 주세요', 'error');
                            },
                          }}
                        />
                      )}
                    >
                      생성하기
                    </DialogButton>
                  </Grid.Unit>
                </Grid>
                <View mt={2}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<OrganizationId_organizationTeachersQuery>>
                        {(queryReference) => (
                          <MATTeacherWindowPaginator fragmentReference={queryReference}>
                            {({ organizationTeachers }, { renderPagination }) => (
                              <>
                                <Text fontSize={1} color={'fg.muted'}>
                                  총 {numberWithCommas(organizationTeachers.totalCount || 0)}개
                                </Text>
                                <View mt={2}>
                                  <MATTeacherConnectionDataTable
                                    matTeacherConnection={organizationTeachers}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState title={'계정이 없어요'} />
                                      </View>
                                    }
                                    renderHead={(columns) => {
                                      const headCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                        textAlign: 'start',
                                        padding: 2,
                                        fontWeight: 'bold',
                                        color: 'fg.muted',
                                      };
                                      return (
                                        <View
                                          as={'thead'}
                                          sx={{
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'border.default',
                                            backgroundColor: 'canvas.subtle',
                                          }}
                                        >
                                          <View as={'tr'}>
                                            {columns.map(({ field, title, width }) => (
                                              <View
                                                key={field}
                                                as={'th'}
                                                sx={{
                                                  minWidth: width,
                                                  ...headCommonStyle,
                                                }}
                                              >
                                                {title}
                                              </View>
                                            ))}
                                            <View as={'th'} sx={{ minWidth: 56, ...headCommonStyle }}>
                                              로그인
                                            </View>
                                          </View>
                                        </View>
                                      );
                                    }}
                                    renderRow={(row, columns, index) => (
                                      <>
                                        {columns.map(({ field, renderValue, width, align = 'start' }) => (
                                          <View
                                            key={field}
                                            as={'td'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: width,
                                              textAlign: align,
                                              padding: 2,
                                            }}
                                          >
                                            {renderValue(row, index)}
                                          </View>
                                        ))}
                                        <View
                                          as={'td'}
                                          sx={{
                                            paddingX: '12px',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'border.default',
                                            textAlign: 'center',
                                          }}
                                        >
                                          <MutationIconButton<OrganizationId_superSignInMutation>
                                            icon={SignInIcon}
                                            size={'small'}
                                            variant={'default'}
                                            aria-label={'이 계정으로 수학대왕 클래스에 로그인하기'}
                                            mutation={graphql`
                                              mutation OrganizationId_superSignInMutation(
                                                $input: MATSuperSignInInput!
                                              ) {
                                                superSignIn(input: $input)
                                              }
                                            `}
                                            input={{ email: row.email }}
                                            config={{
                                              onCompleted: ({ superSignIn: token }) => {
                                                const targetURL = new URL(
                                                  `${process.env.NEXT_PUBLIC_MATHKINGCLASS_END_POINT!}confirm?email=${
                                                    row.email
                                                  }&token=${token}`,
                                                );

                                                targetURL.searchParams.set('by_tcms', tcmsUserName);

                                                window.open(targetURL, '_blank');
                                              },
                                              onError: () => {
                                                toast('다시 시도해 주세요', 'error');
                                              },
                                            }}
                                          />
                                        </View>
                                      </>
                                    )}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </MATTeacherWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            )}
          </QueryFormik>
          <QueryFormik<OrganizationId_organizationStudentsQuery>
            query={organizationStudentsForOrganizationId}
            staticVariables={{ filters: { organization_Exact: organizationId as string }, pageSize: 20 }}
            initialValues={{ page: 1 }}
            options={{ fetchPolicy: 'store-and-network' }}
            enableReinitialize
          >
            {({ submitForm }, queryReference) => (
              <View mt={5}>
                <Grid gapX={3} sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>학생 계정</Text>
                    <View sx={{ mt: '2px' }}>
                      <Text fontSize={0} color={'fg.subtle'}>
                        샘플 계정 비밀번호는 모두 {SAMPLE_ACCOUNT_PASSWORD}으로 만들어져요
                      </Text>
                    </View>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <DialogButton
                      size={'large'}
                      variant={'primary'}
                      leadingIcon={PlusIcon}
                      renderDialog={({ isOpen, closeDialog }) => (
                        <MATOrganizationStudentsCreateDialog
                          matOrganization={organization}
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          config={{
                            onCompleted: () => {
                              toast('학생 계정이 생성됐어요', 'success');
                              submitForm();
                              refetch();
                              closeDialog();
                            },
                            onError: () => {
                              toast('다시 생성해 주세요', 'error');
                            },
                          }}
                        />
                      )}
                    >
                      생성하기
                    </DialogButton>
                  </Grid.Unit>
                </Grid>
                <View mt={2}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<OrganizationId_organizationStudentsQuery>>
                        {(queryReference) => (
                          <MATStudentWindowPaginator fragmentReference={queryReference}>
                            {({ organizationStudents }, { renderPagination }) => (
                              <>
                                <Text fontSize={1} color={'fg.muted'}>
                                  총 {numberWithCommas(organizationStudents.totalCount || 0)}개
                                </Text>
                                <View mt={2}>
                                  <MATStudentConnectionDataTable
                                    matStudentConnection={organizationStudents}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState title={'계정이 없어요'} />
                                      </View>
                                    }
                                    renderHead={(columns) => (
                                      <View
                                        as={'thead'}
                                        sx={{
                                          borderBottomWidth: 1,
                                          borderBottomStyle: 'solid',
                                          borderBottomColor: 'border.default',
                                          backgroundColor: 'canvas.subtle',
                                        }}
                                      >
                                        <View as={'tr'}>
                                          {columns.map(({ field, width, title }) => {
                                            return (
                                              <View
                                                key={field}
                                                as={'th'}
                                                sx={{
                                                  borderWidth: 1,
                                                  borderStyle: 'solid',
                                                  borderColor: 'border.default',
                                                  minWidth: width,
                                                  textAlign: 'start',
                                                  padding: 2,
                                                  fontWeight: 'bold',
                                                  color: 'fg.muted',
                                                }}
                                              >
                                                {title}
                                              </View>
                                            );
                                          })}
                                          <View
                                            as={'th'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: 56,
                                              textAlign: 'start',
                                              padding: 2,
                                              fontWeight: 'bold',
                                              color: 'fg.muted',
                                            }}
                                          >
                                            삭제
                                          </View>
                                        </View>
                                      </View>
                                    )}
                                    renderRow={(row, columns, index) => (
                                      <>
                                        {columns.map(({ field, renderValue, width, align = 'start' }) => (
                                          <View
                                            key={field}
                                            as={'td'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: width,
                                              padding: 2,
                                              textAlign: align,
                                            }}
                                          >
                                            {renderValue(row, index)}
                                          </View>
                                        ))}
                                        <View
                                          as={'td'}
                                          sx={{
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'border.default',
                                            padding: 2,
                                            textAlign: 'center',
                                          }}
                                        >
                                          <MutationConfirmIconButton<OrganizationId_organizationStudentDeleteMutation>
                                            aria-labelledby={'학생 계정 삭제하기'}
                                            mutation={graphql`
                                              mutation OrganizationId_organizationStudentDeleteMutation(
                                                $input: MATOrganizationStudentDeleteInput!
                                              ) {
                                                organizationStudentDelete(input: $input) {
                                                  id @deleteRecord
                                                }
                                              }
                                            `}
                                            icon={TrashIcon}
                                            size={'small'}
                                            variant={'default'}
                                            message={'계정을 삭제할까요?'}
                                            input={{ id: row.id }}
                                            config={{
                                              onCompleted: () => {
                                                toast('삭제가 완료됐어요', 'success');
                                              },
                                            }}
                                          />
                                        </View>
                                      </>
                                    )}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </MATStudentWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            )}
          </QueryFormik>
        </Grid.Unit>
        <Grid.Unit size={1 / 4}>
          <MATOrganizationDescriptionList
            matOrganization={organization}
            type={'activity'}
            titleUnitSize={'max'}
            descriptionUnitSize={'min'}
            renderTitle={(value) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{value}</Text>}
          />
        </Grid.Unit>
      </Grid>
    </View>
  );
};

OrganizationId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
OrganizationId.authenticated = true;
OrganizationId.routes = [
  {
    id: 'organizationId',
    pathname: '/organization/[organizationId]',
    name: '클래스 조직 관리 상세',
    permissions: ['organization_read'],
  },
];

export default OrganizationId;
