/**
 * @generated SignedSource<<af538300fa67aa14fd7cea28fca9c49d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeneratedProblemActionEnum = "generated_problem_activate" | "generated_problem_deactivate" | "%future added value";
export type GeneratedProblemStatusEnum = "active" | "inactive" | "%future added value";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type GeneratedProblemFilter = {
  AND?: GeneratedProblemFilter | null;
  NOT?: GeneratedProblemFilter | null;
  OR?: GeneratedProblemFilter | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  problemGeneratorId_Exact?: string | null;
  status_Exact?: GeneratedProblemStatusEnum | null;
};
export type GeneratedProblemOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery$variables = {
  filters?: GeneratedProblemFilter | null;
  order?: GeneratedProblemOrder | null;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery$data = {
  readonly generatedProblems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly actions: ReadonlyArray<GeneratedProblemActionEnum>;
        readonly id: string;
        readonly status: GeneratedProblemStatusEnum;
      };
    }>;
    readonly totalCount: number | null;
  };
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery = {
  response: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery$data;
  variables: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "GeneratedProblemConnection",
    "kind": "LinkedField",
    "name": "generatedProblems",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneratedProblemEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneratedProblem",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "actions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a7b81d6d00dac25cc477fc03bdb61462",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery",
    "operationKind": "query",
    "text": "query ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery(\n  $filters: GeneratedProblemFilter\n  $order: GeneratedProblemOrder\n) {\n  generatedProblems(filters: $filters, order: $order) {\n    totalCount\n    edges {\n      node {\n        id\n        actions\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "930ea43d70770ef842c88fc5d4c0f212";

export default node;
