/**
 * @generated SignedSource<<cf6862ffb74ebcb2199c11a2d4b92a7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookStatusEnum = "completed" | "ongoing" | "%future added value";
export type BookTypeEnum = "normal" | "school" | "%future added value";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type BookFilter = {
  AND?: BookFilter | null;
  NOT?: BookFilter | null;
  OR?: BookFilter | null;
  publisher_In?: ReadonlyArray<string> | null;
  search?: string | null;
  status_Exact?: BookStatusEnum | null;
  status_In?: ReadonlyArray<BookStatusEnum> | null;
  submittedBy_In?: ReadonlyArray<string> | null;
  type_Exact?: BookTypeEnum | null;
  type_In?: ReadonlyArray<BookTypeEnum> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
};
export type BookOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  sequence?: Ordering | null;
  title?: Ordering | null;
};
export type BookWindowPaginatorRefreshQuery$variables = {
  filters?: BookFilter | null;
  order?: BookOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type BookWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BookWindowPaginator_query">;
};
export type BookWindowPaginatorRefreshQuery = {
  response: BookWindowPaginatorRefreshQuery$data;
  variables: BookWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "BookWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BookConnection",
        "kind": "LinkedField",
        "name": "books",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BookEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Book",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order",
                        "value": {
                          "order": "ASC"
                        }
                      }
                    ],
                    "concreteType": "UnitA",
                    "kind": "LinkedField",
                    "name": "unitAs",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": "unitAs(order:{\"order\":\"ASC\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publisher",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "submittedBy",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a213c1732a0b626cc52d8b6ddf1e49fd",
    "id": null,
    "metadata": {},
    "name": "BookWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query BookWindowPaginatorRefreshQuery(\n  $filters: BookFilter\n  $order: BookOrder\n  $page: Int = 1\n  $pageSize: Int = 20\n) {\n  ...BookWindowPaginator_query_36liK3\n}\n\nfragment BookConnectionDataTable_bookConnection on BookConnection {\n  edges {\n    node {\n      id\n      ...BookStatusLabel_book\n      sequence\n      type\n      title\n      unitAs(order: {order: ASC}) {\n        id\n        title\n      }\n      publisher\n      createdBy {\n        id\n        ...UserAvatarText_user\n      }\n      submittedBy {\n        id\n        ...UserAvatarText_user\n      }\n      created\n    }\n  }\n}\n\nfragment BookStatusLabel_book on Book {\n  status\n}\n\nfragment BookWindowPaginator_query_36liK3 on Query {\n  books(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...BookConnectionDataTable_bookConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "7698626640b77eded15cf2dcd8803719";

export default node;
