import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeviceCameraIcon,
  IterationsIcon,
  TrashIcon,
} from '@primer/octicons-react';
import React, { Suspense, useRef, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import useShortcutKey from '../../../hooks/useShortcutKey';
import useToast from '../../../hooks/useToast';
import { ScrapCreateDialog_scrapCreateMutation } from '../../../relay/__generated__/ScrapCreateDialog_scrapCreateMutation.graphql';
import { ScrapCreateDialog_scrapSource$key } from '../../../relay/__generated__/ScrapCreateDialog_scrapSource.graphql';
import { uploadFile } from '../../../utils/file';
import { isNullable } from '../../../utils/is';
import { scrollToBottom } from '../../../utils/scroll';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPair from '../../core/EnumPair';
import ErrorBoundary from '../../core/ErrorBoundary';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import CoreImage from '../../core/Image';
import ItemList from '../../core/ItemList';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField, { NumberFieldProps } from '../../core/NumberField';
import PdfCropper, { Cropper, PdfCropperProps } from '../../core/PdfCropper';
import SquareView from '../../core/SquareView';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const ScrapCreateDialog_scrapSource = graphql`
  fragment ScrapCreateDialog_scrapSource on ScrapSource {
    id
    title
    type
    solutionPdf {
      id
      objectUrl
    }
    problemPdf {
      id
      objectUrl
    }
    latestScrap {
      problemBookPage
      solutionBookPage
      problemNumber
      problemPage
      solutionPage
    }
  }
`;

type CroppedImageType = {
  width: number;
  height: number;
  objectUrl: string;
};

type FormikStatusType = {
  problems: CroppedImageType[];
  solutions: CroppedImageType[];
};

type Props = {
  scrapSource: ScrapCreateDialog_scrapSource$key;
} & DialogProps &
  Pick<MutationFormikProps<ScrapCreateDialog_scrapCreateMutation>, 'onSubmit' | 'config' | 'connections'>;

const ScrapCreateDialog = ({ scrapSource, onSubmit, config, connections, ...props }: Props) => {
  const { id, title, type, solutionPdf, problemPdf, latestScrap } = useFragment(
    ScrapCreateDialog_scrapSource,
    scrapSource,
  );
  const { toast } = useToast();

  const problemPdfCropperRef = useRef<Cropper>(null);
  const solutionPdfCropperRef = useRef<Cropper>(null);

  const [problemTotalPage, setProblemTotalPage] = useState(1);
  const [solutionTotalPage, setSolutionTotalPage] = useState(1);

  const handleLoadSuccessProblemPdf: PdfCropperProps['onLoadSuccess'] = (pdf) => {
    setProblemTotalPage(pdf.numPages);
  };
  const handleLoadSuccessSolutionPdf: PdfCropperProps['onLoadSuccess'] = (pdf) => {
    setSolutionTotalPage(pdf.numPages);
  };

  const problemPreviewContainerRef = useRef<HTMLDivElement>(null);
  const solutionPreviewContainerRef = useRef<HTMLDivElement>(null);

  const cropBothButtonRef = useRef<HTMLButtonElement>(null);
  const cropProblemButtonRef = useRef<HTMLButtonElement>(null);
  const cropSolutionButtonRef = useRef<HTMLButtonElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);

  useShortcutKey(
    (e) => e.code === 'KeyC',
    () => cropBothButtonRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyV',
    () => cropSolutionButtonRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyX',
    () => cropProblemButtonRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyS',
    () => saveButtonRef.current?.click(),
  );

  const initialValue = {
    scrapSource: { id },
    problemPage: 1,
    problems: [],
    solutionPage: 1,
    solutions: [],
    problemNumber: 1,
    problemBookPage: 1,
    solutionBookPage: 1,
    ...(latestScrap
      ? {
          problemPage: latestScrap.problemPage,
          solutionPage: latestScrap.solutionPage,
          problemNumber: latestScrap.problemNumber + 1,
          problemBookPage: latestScrap.problemBookPage,
          solutionBookPage: latestScrap.solutionBookPage,
        }
      : {}),
  };

  const mergeCroppedImages = async (images: CroppedImageType[]) => {
    const maxImageWidth = Math.max(...images.map(({ width }) => width));
    const resizedImages = images.map((image) => ({
      ...image,
      width: maxImageWidth,
      height: (image.height * maxImageWidth) / image.width,
    }));

    const canvas = document.createElement('canvas');
    canvas.width = Math.max(...resizedImages.map(({ width }) => width));
    canvas.height = resizedImages.reduce((acc, cur) => acc + cur.height, 0);
    const ctx = canvas.getContext('2d');

    resizedImages.forEach(({ width, height, objectUrl }, i, resizedImages) => {
      const imageElement = new Image();
      imageElement.src = objectUrl;
      imageElement.width = width;
      imageElement.height = height;
      ctx?.drawImage(
        imageElement,
        0,
        resizedImages.slice(0, i).reduce((acc, cur) => acc + cur.height, 0),
        width,
        height,
      );
    });

    const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
    canvas.remove();

    if (isNullable(blob)) return Promise.reject('empty_merged_image');

    return blob;
  };

  return (
    <Dialog {...props}>
      <Suspense>
        <MutationFormik<ScrapCreateDialog_scrapCreateMutation>
          mutation={graphql`
            mutation ScrapCreateDialog_scrapCreateMutation($input: ScrapCreateInput!) {
              scrapCreate(input: $input) {
                id
                problemPage
                problemBookPage
                solutionPage
                solutionBookPage
                problemNumber
                scrapSource {
                  id
                  ...ScrapSourceScrapPaginator_scrapSource
                  ...ScrapSourceActionsMutationButtonStack_ScrapSource
                }
              }
            }
          `}
          initialValues={initialValue}
          onSubmit={onSubmit}
          config={{
            ...config,
            onCompleted: (response, _error, formikHelper) => {
              config?.onCompleted?.(response, _error, formikHelper);
              const { scrapCreate } = response;
              formikHelper.resetForm({
                values: {
                  ...initialValue,
                  problemPage: scrapCreate.problemPage,
                  solutionPage: scrapCreate.solutionPage,
                  problemBookPage: scrapCreate.problemPage,
                  solutionBookPage: scrapCreate.solutionBookPage,
                  problemNumber: scrapCreate.problemNumber + 1,
                },
              });
            },
          }}
          initialStatus={{ problems: [], solutions: [] } as FormikStatusType}
          connections={connections}
          enableReinitialize={false}
        >
          {({ values, setFieldValue, status: _status, setStatus, setSubmitting, submitForm }) => {
            const status = _status as FormikStatusType;
            const handleChangeProblemPdfPage: NumberFieldProps['onChange'] = (e) => {
              const value = e.target.value === '' ? '' : Number(e.target.value);
              const isValid = value === '' || (value >= 1 && value <= problemTotalPage);
              if (isValid) {
                setFieldValue('problemPage', value);
                setFieldValue('problemBookPage', value);
              }
            };
            const handleChangeSolutionPdfPage: NumberFieldProps['onChange'] = (e) => {
              const value = e.target.value === '' ? '' : Number(e.target.value);
              const isValid = value === '' || (value >= 1 && value <= solutionTotalPage);
              if (isValid) {
                setFieldValue('solutionPage', value);
                setFieldValue('solutionBookPage', value);
              }
            };

            const handleClickProblemPdfPageIconButton = (offset: number) => {
              const value = values.problemPage + offset;
              const isValid = value >= 1 && value <= problemTotalPage;
              if (isValid) {
                setFieldValue('problemPage', value);
                setFieldValue('problemBookPage', value);
              }
            };
            const handleClickSolutionPdfPageIconButton = (offset: number) => {
              const value = values.solutionPage + offset;
              const isValid = value >= 1 && value <= solutionTotalPage;
              if (isValid) {
                setFieldValue('solutionPage', value);
                setFieldValue('solutionBookPage', value);
              }
            };

            const getCroppedImageObjectFromCropper = (cropper: Cropper): CroppedImageType => {
              const croppedCanvas = cropper.getCroppedCanvas({
                minWidth: 335,
                minHeight: 335,
                imageSmoothingQuality: 'high',
              });
              return {
                width: croppedCanvas.width,
                height: croppedCanvas.height,
                objectUrl: croppedCanvas.toDataURL(),
              };
            };

            const handleClickProblemTrashButton = (index: number) => {
              setStatus({ ...status, problems: status.problems.filter((_, i) => i !== index) });
            };
            const handleClickSolutionTrashButton = (index: number) => {
              setStatus({ ...status, solutions: status.solutions.filter((_, i) => i !== index) });
            };
            const availableSubmit = status.solutions.length > 0 && status.problems.length > 0;

            return (
              <>
                <Dialog.Header>
                  <Grid gapX={1} sx={{ paddingRight: 4, alignItems: 'center' }}>
                    <Grid.Unit size={'max'}>
                      <Stack gapX={1}>
                        <Stack.Item>
                          <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>스크랩하기</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <View>
                            <Label variant={'accent'}>
                              <EnumPair typename={'ScrapSourceTypeEnum'}>{type}</EnumPair>
                              {` · ${title}`}
                            </Label>
                          </View>
                        </Stack.Item>
                      </Stack>
                    </Grid.Unit>
                    <Grid.Unit size={'min'}>
                      <Text fontSize={0} color={'fg.subtle'} whiteSpace={'pre-wrap'}>
                        {'[단축키] (S):저장 (X):문제 스크랩 (C):함께 스크랩 (V):해설 스크랩'}
                      </Text>
                    </Grid.Unit>
                    <Grid.Unit size={'min'}>
                      <Button
                        ref={saveButtonRef}
                        variant={'primary'}
                        onClick={async () => {
                          setSubmitting(true);

                          try {
                            await setFieldValueAfterMergingAndUploadingImage('problems', status.problems);
                            await setFieldValueAfterMergingAndUploadingImage('solutions', status.solutions);

                            setTimeout(() => submitForm());
                          } catch (e) {
                            if (e === 'empty_merged_image') {
                              toast('이미지 병합이 제대로 이루어지지 않았습니다', 'error');
                            } else if (e === 'block_multipart_upload')
                              toast('사진 크기가 너무 큽니다. 더 작게 캡쳐해주세요.', 'error');
                            else {
                              toast('다시 시도해 주세요;m&u', 'error');
                            }
                          }

                          setSubmitting(false);

                          async function setFieldValueAfterMergingAndUploadingImage(
                            fieldName: string,
                            images: CroppedImageType[],
                          ) {
                            const blob = await mergeCroppedImages(images);
                            const [, extension] = blob.type.split('/');
                            const value = await uploadFile(blob, {
                              multipart: false,
                              fieldName: fieldName,
                              formatFileName: () => `${id}-${values.problemNumber}-${fieldName}.${extension}`,
                            });
                            setFieldValue(fieldName, value);
                          }
                        }}
                        leadingIcon={CheckIcon}
                        disabled={!availableSubmit}
                      >
                        저장 및 다음
                      </Button>
                    </Grid.Unit>
                  </Grid>
                </Dialog.Header>
                <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
                  <Grid.Unit
                    size={[3 / 4, 3 / 4, 3 / 4, 3 / 4, 4 / 5]}
                    sx={{ padding: 3, height: '100%', overflowY: 'auto' }}
                  >
                    <NonFieldError sx={{ marginBottom: 3 }} />
                    <Grid gapX={4}>
                      <Grid.Unit size={1 / 2}>
                        <Grid sx={{ marginBottom: 3, alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Stack gapX={3}>
                              <Stack.Item>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  문제
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Stack gapX={1}>
                                  <Stack.Item>
                                    <IconButton
                                      icon={ChevronLeftIcon}
                                      aria-label={'previous problem pdf page'}
                                      size={'large'}
                                      disabled={values.problemPage <= 1}
                                      onClick={() => handleClickProblemPdfPageIconButton(-1)}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <NumberField
                                      sx={{ width: 60 }}
                                      label={'문제 페이지'}
                                      labelConfig={{ visuallyHidden: true }}
                                      name={'problemPage'}
                                      min={1}
                                      max={problemTotalPage}
                                      onChange={handleChangeProblemPdfPage}
                                      size={'large'}
                                      debounce
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <IconButton
                                      icon={ChevronRightIcon}
                                      aria-label={'next problem pdf page'}
                                      size={'large'}
                                      disabled={values.problemPage >= problemTotalPage}
                                      onClick={() => handleClickProblemPdfPageIconButton(1)}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Text fontSize={2}>{`/ ${problemTotalPage || 1}P`}</Text>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <Stack gapX={2}>
                              <Stack.Item>
                                <IconButton
                                  icon={IterationsIcon}
                                  aria-label={'rotate problem pdf'}
                                  size={'large'}
                                  onClick={() => {
                                    if (!isNullable(problemPdfCropperRef.current))
                                      problemPdfCropperRef.current.rotate(270);
                                  }}
                                />
                              </Stack.Item>
                              <Stack.Item>
                                <IconButton
                                  ref={cropProblemButtonRef}
                                  icon={DeviceCameraIcon}
                                  aria-label={'crop problem pdf'}
                                  size={'large'}
                                  onClick={() => {
                                    if (!isNullable(problemPdfCropperRef.current)) {
                                      setStatus({
                                        ...status,
                                        problems: [
                                          ...status.problems,
                                          getCroppedImageObjectFromCropper(problemPdfCropperRef.current),
                                        ],
                                      });
                                      setTimeout(() => {
                                        if (problemPreviewContainerRef.current)
                                          scrollToBottom(problemPreviewContainerRef.current, {});
                                      }, 300);
                                    }
                                  }}
                                />
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                        </Grid>
                        <ErrorBoundary>
                          <PdfCropper
                            ref={problemPdfCropperRef}
                            src={problemPdf.objectUrl}
                            pageNumber={values.problemPage}
                            onLoadSuccess={handleLoadSuccessProblemPdf}
                            cropperHeight={'75vh'}
                          />
                        </ErrorBoundary>
                      </Grid.Unit>
                      <Grid.Unit size={1 / 2}>
                        <Grid sx={{ marginBottom: 3, alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Stack gapX={3}>
                              <Stack.Item>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  {'해설'}
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Stack gapX={1}>
                                  <Stack.Item>
                                    <IconButton
                                      icon={ChevronLeftIcon}
                                      aria-label={'previous solution pdf page'}
                                      size={'large'}
                                      disabled={values.solutionPage <= 1}
                                      onClick={() => handleClickSolutionPdfPageIconButton(-1)}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <NumberField
                                      sx={{ width: 60 }}
                                      label={'해설 페이지'}
                                      labelConfig={{ visuallyHidden: true }}
                                      name={'solutionPage'}
                                      min={1}
                                      max={solutionTotalPage}
                                      size={'large'}
                                      onChange={handleChangeSolutionPdfPage}
                                      debounce
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <IconButton
                                      icon={ChevronRightIcon}
                                      aria-label={'next solution pdf page'}
                                      size={'large'}
                                      disabled={values.solutionPage >= solutionTotalPage}
                                      onClick={() => handleClickSolutionPdfPageIconButton(1)}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Text fontSize={2}>{`/ ${solutionTotalPage || 1}P`}</Text>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <Stack gapX={2}>
                              <Stack.Item>
                                <IconButton
                                  icon={IterationsIcon}
                                  aria-label={'rotate solution pdf'}
                                  size={'large'}
                                  onClick={() => {
                                    if (!isNullable(solutionPdfCropperRef.current))
                                      solutionPdfCropperRef.current.rotate(270);
                                  }}
                                />
                              </Stack.Item>
                              <Stack.Item>
                                <IconButton
                                  ref={cropSolutionButtonRef}
                                  icon={DeviceCameraIcon}
                                  aria-label={'crop solution pdf'}
                                  size={'large'}
                                  onClick={() => {
                                    if (!isNullable(solutionPdfCropperRef.current)) {
                                      setStatus({
                                        ...status,
                                        solutions: [
                                          ...status.solutions,
                                          getCroppedImageObjectFromCropper(solutionPdfCropperRef.current),
                                        ],
                                      });

                                      setTimeout(() => {
                                        if (solutionPreviewContainerRef.current)
                                          scrollToBottom(solutionPreviewContainerRef.current, {});
                                      }, 300);
                                    }
                                  }}
                                />
                              </Stack.Item>
                              <Stack.Item>
                                <Button
                                  ref={cropBothButtonRef}
                                  size={'large'}
                                  onClick={() => {
                                    if (
                                      !isNullable(problemPdfCropperRef.current) &&
                                      !isNullable(solutionPdfCropperRef.current)
                                    ) {
                                      setStatus({
                                        problems: [
                                          ...status.problems,
                                          getCroppedImageObjectFromCropper(problemPdfCropperRef.current),
                                        ],
                                        solutions: [
                                          ...status.solutions,
                                          getCroppedImageObjectFromCropper(solutionPdfCropperRef.current),
                                        ],
                                      });

                                      setTimeout(() => {
                                        if (problemPreviewContainerRef.current)
                                          scrollToBottom(problemPreviewContainerRef.current, {});
                                        if (solutionPreviewContainerRef.current)
                                          scrollToBottom(solutionPreviewContainerRef.current, {});
                                      }, 300);
                                    }
                                  }}
                                >
                                  함께 스크랩
                                </Button>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                        </Grid>
                        <ErrorBoundary>
                          <PdfCropper
                            ref={solutionPdfCropperRef}
                            src={solutionPdf.objectUrl}
                            pageNumber={values.solutionPage}
                            onLoadSuccess={handleLoadSuccessSolutionPdf}
                            cropperHeight={'75vh'}
                          />
                        </ErrorBoundary>
                      </Grid.Unit>
                    </Grid>
                  </Grid.Unit>
                  <Grid.Unit size={[1 / 4, 1 / 4, 1 / 4, 1 / 4, 1 / 5]}>
                    <View
                      sx={{
                        'padding': 3,
                        'backgroundColor': 'canvas.inset',
                        'height': '100%',
                        'overflowY': 'auto',
                        '&>*:not(:first-child)': { marginTop: 3 },
                      }}
                    >
                      <View>
                        <Grid sx={{ alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Stack gapX={1}>
                              <Stack.Item>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  문제
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Text
                                  fontSize={1}
                                  fontWeight={'bold'}
                                  color={status.problems.length === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                >
                                  {status.problems.length}
                                </Text>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <Stack gapX={2}>
                              <Stack.Item>
                                <Stack gapX={1}>
                                  <Stack.Item>
                                    <NumberField
                                      sx={{ width: 60 }}
                                      label={'문제 책 페이지'}
                                      labelConfig={{ visuallyHidden: true }}
                                      name={'problemBookPage'}
                                      min={1}
                                      size={'large'}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Text fontSize={1}>P의</Text>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                              <Stack.Item>
                                <Stack gapX={1}>
                                  <Stack.Item>
                                    <NumberField
                                      sx={{ width: 60 }}
                                      label={'문제 번호'}
                                      labelConfig={{ visuallyHidden: true }}
                                      name={'problemNumber'}
                                      min={1}
                                      size={'large'}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Text fontSize={1}>번</Text>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                        </Grid>
                        <SquareView
                          sx={{ borderColor: 'border.default', borderStyle: 'solid', borderWidth: 1, marginTop: 2 }}
                        >
                          <View
                            ref={problemPreviewContainerRef}
                            sx={{ height: '100%', width: '100%', overflowY: 'auto', backgroundColor: 'canvas.default' }}
                          >
                            <ItemList
                              items={status.problems}
                              renderItem={({ objectUrl }, index) => (
                                <View
                                  sx={{
                                    'position': 'relative',
                                    '&:hover': {
                                      '*': {
                                        display: 'block',
                                      },
                                    },
                                  }}
                                >
                                  <View
                                    sx={{
                                      position: 'absolute',
                                      display: 'none',
                                      width: '100%',
                                      height: '100%',
                                      backgroundColor: 'fg.default',
                                      opacity: 0.2,
                                      zIndex: 1,
                                    }}
                                  />
                                  <IconButton
                                    sx={{ position: 'absolute', display: 'none', left: 2, bottom: 2, zIndex: 1 }}
                                    size={'large'}
                                    icon={TrashIcon}
                                    aria-label={`delete problem image${index}`}
                                    onClick={() => {
                                      handleClickProblemTrashButton(index);
                                    }}
                                  />
                                  <CoreImage
                                    src={objectUrl || ''}
                                    alt={`problem image${index}`}
                                    html
                                    style={{ display: 'block' }}
                                  />
                                </View>
                              )}
                              renderItemWrapper={(children, { objectUrl }, index) => (
                                <View key={objectUrl + index}>{children}</View>
                              )}
                              emptyState={
                                <View
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <Text fontSize={2} color={'neutral.emphasis'}>
                                    {'문제를 스크랩 해주세요'}
                                  </Text>
                                </View>
                              }
                            />
                          </View>
                        </SquareView>
                      </View>
                      <View>
                        <Grid sx={{ alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Stack gapX={1}>
                              <Stack.Item>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  해설
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Text
                                  fontSize={1}
                                  fontWeight={'bold'}
                                  color={status.solutions.length === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                >
                                  {status.solutions.length}
                                </Text>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <Stack gapX={2}>
                              <Stack.Item>
                                <Stack gapX={1}>
                                  <Stack.Item>
                                    <NumberField
                                      sx={{ width: 60 }}
                                      label={'해설 책 페이지'}
                                      labelConfig={{ visuallyHidden: true }}
                                      name={'solutionBookPage'}
                                      min={1}
                                      size={'large'}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Text fontSize={1}>P의</Text>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                              <Stack.Item>
                                <Stack gapX={1}>
                                  <Stack.Item>
                                    <NumberField
                                      sx={{ width: 60 }}
                                      label={'문제 번호'}
                                      labelConfig={{ visuallyHidden: true }}
                                      name={'problemNumber'}
                                      min={1}
                                      size={'large'}
                                    />
                                  </Stack.Item>
                                  <Stack.Item>
                                    <Text fontSize={1}>번</Text>
                                  </Stack.Item>
                                </Stack>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                        </Grid>
                        <SquareView
                          sx={{ borderColor: 'border.default', borderStyle: 'solid', borderWidth: 1, marginTop: 2 }}
                        >
                          <View
                            ref={solutionPreviewContainerRef}
                            sx={{ height: '100%', width: '100%', overflowY: 'auto', backgroundColor: 'canvas.default' }}
                          >
                            <ItemList
                              items={status.solutions}
                              renderItem={({ objectUrl }, index) => (
                                <View
                                  sx={{
                                    'position': 'relative',
                                    '&:hover': {
                                      '*': {
                                        display: 'block',
                                      },
                                    },
                                  }}
                                >
                                  <View
                                    sx={{
                                      position: 'absolute',
                                      display: 'none',
                                      width: '100%',
                                      height: '100%',
                                      backgroundColor: 'fg.default',
                                      opacity: 0.2,
                                      zIndex: 1,
                                    }}
                                  />
                                  <IconButton
                                    sx={{ position: 'absolute', display: 'none', left: 2, bottom: 2, zIndex: 1 }}
                                    size={'large'}
                                    icon={TrashIcon}
                                    aria-label={`delete solution image${index}`}
                                    onClick={() => {
                                      handleClickSolutionTrashButton(index);
                                    }}
                                  />
                                  <CoreImage
                                    src={objectUrl || ''}
                                    alt={`solution image${index}`}
                                    html
                                    style={{ display: 'block' }}
                                  />
                                </View>
                              )}
                              renderItemWrapper={(children, { objectUrl }, index) => (
                                <View key={objectUrl + index}>{children}</View>
                              )}
                              emptyState={
                                <View
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                  }}
                                >
                                  <Text fontSize={2} color={'neutral.emphasis'}>
                                    {'해설을 스크랩 해주세요'}
                                  </Text>
                                </View>
                              }
                            />
                          </View>
                        </SquareView>
                      </View>
                    </View>
                  </Grid.Unit>
                </Grid>
              </>
            );
          }}
        </MutationFormik>
      </Suspense>
    </Dialog>
  );
};

export default ScrapCreateDialog;
