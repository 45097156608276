/**
 * @generated SignedSource<<d2cf1aa18fc4707273518d1ba3c49ef7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScrapCreateInput = {
  problemBookPage: number;
  problemNumber: number;
  problemPage: number;
  problems: ReadonlyArray<Upload>;
  scrapSource: NodeInput;
  solutionBookPage: number;
  solutionPage: number;
  solutions: ReadonlyArray<Upload>;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type NodeInput = {
  id: string;
};
export type ScrapCreateDialog_scrapCreateMutation$variables = {
  input: ScrapCreateInput;
};
export type ScrapCreateDialog_scrapCreateMutation$data = {
  readonly scrapCreate: {
    readonly id: string;
    readonly problemBookPage: number;
    readonly problemNumber: number;
    readonly problemPage: number;
    readonly scrapSource: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceActionsMutationButtonStack_ScrapSource" | "ScrapSourceScrapPaginator_scrapSource">;
    };
    readonly solutionBookPage: number;
    readonly solutionPage: number;
  };
};
export type ScrapCreateDialog_scrapCreateMutation = {
  response: ScrapCreateDialog_scrapCreateMutation$data;
  variables: ScrapCreateDialog_scrapCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemPage",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemBookPage",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionPage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionBookPage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapCreateDialog_scrapCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Scrap",
        "kind": "LinkedField",
        "name": "scrapCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ScrapSource",
            "kind": "LinkedField",
            "name": "scrapSource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ScrapSourceScrapPaginator_scrapSource"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ScrapSourceActionsMutationButtonStack_ScrapSource"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapCreateDialog_scrapCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Scrap",
        "kind": "LinkedField",
        "name": "scrapCreate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ScrapSource",
            "kind": "LinkedField",
            "name": "scrapSource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ScrapConnection",
                "kind": "LinkedField",
                "name": "scraps",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScrapEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Scrap",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "ScrapSourceScrapPaginator_scrapSource_scraps",
                "kind": "LinkedHandle",
                "name": "scraps"
              },
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "solutionPdf",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "problemPdf",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Scrap",
                "kind": "LinkedField",
                "name": "latestScrap",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4f468331dd39d9cce68ce4e8e9bba304",
    "id": null,
    "metadata": {},
    "name": "ScrapCreateDialog_scrapCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ScrapCreateDialog_scrapCreateMutation(\n  $input: ScrapCreateInput!\n) {\n  scrapCreate(input: $input) {\n    id\n    problemPage\n    problemBookPage\n    solutionPage\n    solutionBookPage\n    problemNumber\n    scrapSource {\n      id\n      ...ScrapSourceScrapPaginator_scrapSource\n      ...ScrapSourceActionsMutationButtonStack_ScrapSource\n    }\n  }\n}\n\nfragment ScrapCreateDialog_scrapSource on ScrapSource {\n  id\n  title\n  type\n  solutionPdf {\n    id\n    objectUrl\n  }\n  problemPdf {\n    id\n    objectUrl\n  }\n  latestScrap {\n    problemBookPage\n    solutionBookPage\n    problemNumber\n    problemPage\n    solutionPage\n    id\n  }\n}\n\nfragment ScrapItem_scrap on Scrap {\n  id\n  problemBookPage\n  solutionBookPage\n  problemNumber\n}\n\nfragment ScrapSourceActionsMutationButtonStack_ScrapSource on ScrapSource {\n  id\n  actions\n  ...ScrapCreateDialog_scrapSource\n}\n\nfragment ScrapSourceScrapConnectionDataTable_scrapConnection on ScrapConnection {\n  edges {\n    node {\n      id\n      actions\n      problemNumber\n      problemBookPage\n      solutionBookPage\n    }\n  }\n}\n\nfragment ScrapSourceScrapPaginator_scrapSource on ScrapSource {\n  scraps {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        actions\n        ...ScrapItem_scrap\n        __typename\n      }\n    }\n    ...ScrapSourceScrapConnectionDataTable_scrapConnection\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "9af5e59314e01ad5e57114292390b8b3";

export default node;
