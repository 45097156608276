/**
 * @generated SignedSource<<bcd80a93f3499e5ba043b18b98f9bc71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskFinalLabelingInput = {
  difficulty: number;
  task: string;
  unitDs: ReadonlyArray<string>;
};
export type TaskFinalLabelingDialog_taskFinalLabelingMutation$variables = {
  input: TaskFinalLabelingInput;
};
export type TaskFinalLabelingDialog_taskFinalLabelingMutation$data = {
  readonly taskFinalLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type TaskFinalLabelingDialog_taskFinalLabelingMutation = {
  response: TaskFinalLabelingDialog_taskFinalLabelingMutation$data;
  variables: TaskFinalLabelingDialog_taskFinalLabelingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TaskLabeling",
    "kind": "LinkedField",
    "name": "taskFinalLabeling",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "difficulty",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "order": "DESC"
            }
          }
        ],
        "concreteType": "UnitD",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskFinalLabelingDialog_taskFinalLabelingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskFinalLabelingDialog_taskFinalLabelingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "57b790e333448f0dca06e57e0b32adf3",
    "id": null,
    "metadata": {},
    "name": "TaskFinalLabelingDialog_taskFinalLabelingMutation",
    "operationKind": "mutation",
    "text": "mutation TaskFinalLabelingDialog_taskFinalLabelingMutation(\n  $input: TaskFinalLabelingInput!\n) {\n  taskFinalLabeling(input: $input) {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f053ab6fdad619b022cfec37622d24c8";

export default node;
