import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  MATOrganizationConnectionDataTable_matOrganizationConnection$key,
  MATOrganizationConnectionDataTable_matOrganizationConnection$data,
} from '../../../relay/__generated__/MATOrganizationConnectionDataTable_matOrganizationConnection.graphql';
import { formatDistanceToNowStrict } from '../../../utils/date';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Spinner from '../../core/Spinner';

const MATOrganizationConnectionDataTable_matOrganizationConnection = graphql`
  fragment MATOrganizationConnectionDataTable_matOrganizationConnection on MATOrganizationConnection {
    edges {
      node {
        id
        type
        title
        currentInvoice {
          id
          premiumType
          startDate
          endDate
        }
        createdBy
        created
      }
    }
  }
`;

type Props = {
  matOrganizationConnection: MATOrganizationConnectionDataTable_matOrganizationConnection$key;
} & Omit<
  DataTableProps<MATOrganizationConnectionDataTable_matOrganizationConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const MATOrganizationConnectionDataTable = ({ matOrganizationConnection, ...props }: Props) => {
  const { edges } = useFragment(
    MATOrganizationConnectionDataTable_matOrganizationConnection,
    matOrganizationConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'type',
          title: '조직 타입',
          renderValue: ({ type }) => (
            <Suspense fallback={'...'}>
              <EnumPair typename={'MATOrganizationTypeEnum'}>{type}</EnumPair>
            </Suspense>
          ),
          width: 92,
        },
        {
          field: 'title',
          title: '조직 이름',
          renderValue: ({ title }) => title,
          width: 340,
        },
        {
          field: 'currentInvoice.premiumType',
          title: '타입',
          renderValue: ({ currentInvoice }) =>
            currentInvoice ? (
              <Suspense fallback={<Spinner size={'small'} />}>
                <EnumPair typename={'MATOrganizationPremiumTypeEnum'}>{currentInvoice.premiumType}</EnumPair>
              </Suspense>
            ) : (
              '-'
            ),
          width: 92,
        },
        {
          field: 'currentInvoice.startDate',
          title: '이용 시작일',
          renderValue: ({ currentInvoice }) =>
            currentInvoice ? <DateText format={'P'}>{currentInvoice.startDate}</DateText> : '-',
          width: 160,
        },
        {
          field: 'currentInvoice.endDate',
          title: '이용 종료일',
          renderValue: ({ currentInvoice }) =>
            currentInvoice ? (
              <>
                <DateText format={'P'}>{currentInvoice.endDate}</DateText> (
                {formatDistanceToNowStrict(currentInvoice.endDate, {
                  unit: 'day',
                  addSuffix: false,
                })}{' '}
                남음)
              </>
            ) : (
              '-'
            ),
          width: 180,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => createdBy,
          width: 92,
        },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default MATOrganizationConnectionDataTable;
