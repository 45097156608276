/**
 * @generated SignedSource<<36e0162c7825a7dff6c262467a133f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type MATPremiumApplyHistoryFilter = {
  AND?: MATPremiumApplyHistoryFilter | null;
  NOT?: MATPremiumApplyHistoryFilter | null;
  OR?: MATPremiumApplyHistoryFilter | null;
  distinct?: boolean | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  isValid?: boolean | null;
  userId_Exact?: string | null;
};
export type MATPremiumApplyHistoryOrder = {
  endAt?: Ordering | null;
};
export type PremiumPaginatorRefreshQuery$variables = {
  after?: string | null;
  filters?: MATPremiumApplyHistoryFilter | null;
  first?: number | null;
  order?: MATPremiumApplyHistoryOrder | null;
};
export type PremiumPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PremiumPaginator_query">;
};
export type PremiumPaginatorRefreshQuery = {
  response: PremiumPaginatorRefreshQuery$data;
  variables: PremiumPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": {
      "isValid": true
    },
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": 5,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": {
      "endAt": "DESC"
    },
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PremiumPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PremiumPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PremiumPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATPremiumApplyHistoryConnection",
        "kind": "LinkedField",
        "name": "premiums",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MATPremiumApplyHistoryEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MATPremiumApplyHistory",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATInternalItem",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "premiumType",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "PremiumPaginator_query_premiums",
        "kind": "LinkedHandle",
        "name": "premiums"
      }
    ]
  },
  "params": {
    "cacheID": "a58f3697779a9fd3c67810915ec058e1",
    "id": null,
    "metadata": {},
    "name": "PremiumPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query PremiumPaginatorRefreshQuery(\n  $after: String\n  $filters: MATPremiumApplyHistoryFilter = {isValid: true}\n  $first: Int = 5\n  $order: MATPremiumApplyHistoryOrder = {endAt: DESC}\n) {\n  ...PremiumPaginator_query_2rfEMg\n}\n\nfragment PremiumConnectionDataTable_PremiumApplyHistoryConnection on MATPremiumApplyHistoryConnection {\n  edges {\n    node {\n      id\n      startAt\n      endAt\n      item {\n        name\n        premiumType\n        id\n      }\n      ...PremiumUpdateDialog_premium\n      ...PremiumDeleteConfirmButton_premium\n    }\n  }\n}\n\nfragment PremiumDeleteConfirmButton_premium on MATPremiumApplyHistory {\n  id\n}\n\nfragment PremiumPaginator_query_2rfEMg on Query {\n  premiums(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        id\n        startAt\n        endAt\n        item {\n          name\n          premiumType\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    ...PremiumConnectionDataTable_PremiumApplyHistoryConnection\n  }\n}\n\nfragment PremiumUpdateDialog_premium on MATPremiumApplyHistory {\n  id\n  startAt\n  endAt\n}\n"
  }
};
})();

(node as any).hash = "785b191815c8df66ee75bba2cb4c8814";

export default node;
