/**
 * @generated SignedSource<<5c8d20a2da04493db127befb51f21e19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATOrganizationPremiumTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationConnectionDataTable_matOrganizationConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: string | null;
      readonly currentInvoice: {
        readonly endDate: any;
        readonly id: string;
        readonly premiumType: MATOrganizationPremiumTypeEnum;
        readonly startDate: any;
      } | null;
      readonly id: string;
      readonly title: string;
      readonly type: MATOrganizationTypeEnum;
    };
  }>;
  readonly " $fragmentType": "MATOrganizationConnectionDataTable_matOrganizationConnection";
};
export type MATOrganizationConnectionDataTable_matOrganizationConnection$key = {
  readonly " $data"?: MATOrganizationConnectionDataTable_matOrganizationConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationConnectionDataTable_matOrganizationConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationConnectionDataTable_matOrganizationConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MATOrganizationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MATOrganization",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MATInvoice",
              "kind": "LinkedField",
              "name": "currentInvoice",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "premiumType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endDate",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdBy",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MATOrganizationConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0f8f80065b872e3f5e5f15c83ecee90f";

export default node;
