/**
 * @generated SignedSource<<fcffbaafe5d542422222ce1a9302beb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type SchoolRegionEnum = "busan" | "chungbuk" | "chungnam" | "daegu" | "daejeon" | "gangwon" | "gwangju" | "gyeongbuk" | "gyeonggi" | "gyeongnam" | "incheon" | "jeju" | "jeongbuk" | "jeonnam" | "sejong" | "seoul" | "ulsan" | "%future added value";
export type SchoolYearEnum = "high_1" | "high_2" | "high_3" | "middle_1" | "middle_2" | "middle_3" | "%future added value";
export type SchoolFilter = {
  AND?: SchoolFilter | null;
  NOT?: SchoolFilter | null;
  OR?: SchoolFilter | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  region_In?: ReadonlyArray<SchoolRegionEnum> | null;
  schoolYear_In?: ReadonlyArray<SchoolYearEnum> | null;
  search?: string | null;
};
export type SchoolOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  title?: Ordering | null;
};
export type SchoolPaginatorSearchOverlayTokenField_schoolsQuery$variables = {
  after?: string | null;
  filters?: SchoolFilter | null;
  first?: number | null;
  order?: SchoolOrder | null;
};
export type SchoolPaginatorSearchOverlayTokenField_schoolsQuery$data = {
  readonly schools: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SchoolPaginator_query">;
};
export type SchoolPaginatorSearchOverlayTokenField_schoolsQuery = {
  response: SchoolPaginatorSearchOverlayTokenField_schoolsQuery$data;
  variables: SchoolPaginatorSearchOverlayTokenField_schoolsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolPaginatorSearchOverlayTokenField_schoolsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SchoolConnection",
        "kind": "LinkedField",
        "name": "schools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "School",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "SchoolPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SchoolPaginatorSearchOverlayTokenField_schoolsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SchoolConnection",
        "kind": "LinkedField",
        "name": "schools",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "School",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "location",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "SchoolPaginator_query_schools",
        "kind": "LinkedHandle",
        "name": "schools"
      }
    ]
  },
  "params": {
    "cacheID": "02c41f35ccdd3bfa3c0dda275dee7e31",
    "id": null,
    "metadata": {},
    "name": "SchoolPaginatorSearchOverlayTokenField_schoolsQuery",
    "operationKind": "query",
    "text": "query SchoolPaginatorSearchOverlayTokenField_schoolsQuery(\n  $filters: SchoolFilter\n  $order: SchoolOrder\n  $first: Int\n  $after: String\n) {\n  schools(filters: $filters, order: $order, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n  ...SchoolPaginator_query_2rfEMg\n}\n\nfragment SchoolItem_school on School {\n  title\n  location\n}\n\nfragment SchoolPaginator_query_2rfEMg on Query {\n  schools(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        title\n        ...SchoolItem_school\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a9c6864ef370aeb2b29cf5843f4175d";

export default node;
