/**
 * @generated SignedSource<<bc82d7136cf2623cfb221ef6e1b9e48f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
export type UnitSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskHintFeedbackSaveDialog_task$data = {
  readonly hint: {
    readonly aiCreationModified: boolean;
    readonly id: string;
    readonly isMeaningless: boolean;
    readonly isSimpleModified: boolean;
    readonly text: string | null;
    readonly usedAiSuggestion: {
      readonly id: string;
    } | null;
  } | null;
  readonly id: string;
  readonly problem: {
    readonly id: string;
    readonly problem: string | null;
    readonly problemType: TaskProblemTypeEnum;
    readonly solution: string | null;
  } | null;
  readonly problemSolveInfo: {
    readonly specialAnswers: ReadonlyArray<{
      readonly answer: string | null;
      readonly percentage: number | null;
      readonly rank: number;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo">;
  } | null;
  readonly publishedUnitDs: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UnitDItem_unitD">;
  }>;
  readonly sequence: number;
  readonly submitFeedback: {
    readonly general: string | null;
    readonly id: string;
    readonly special: string | null;
    readonly specialAnswer: string | null;
  } | null;
  readonly title: string;
  readonly unit: {
    readonly schoolType: UnitSchoolTypeEnum;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"TaskDescriptionList_task" | "TaskHintAiSuggestionPaginator_query">;
  readonly " $fragmentType": "TaskHintFeedbackSaveDialog_task";
};
export type TaskHintFeedbackSaveDialog_task$key = {
  readonly " $data"?: TaskHintFeedbackSaveDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHintFeedbackSaveDialog_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskHintFeedbackSaveDialog_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solution",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Unit",
      "kind": "LinkedField",
      "name": "unit",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskHint",
      "kind": "LinkedField",
      "name": "hint",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMeaningless",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSimpleModified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aiCreationModified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskHintAiSuggestion",
          "kind": "LinkedField",
          "name": "usedAiSuggestion",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "created": "DESC"
          }
        }
      ],
      "kind": "FragmentSpread",
      "name": "TaskHintAiSuggestionPaginator_query"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskSubmitFeedback",
      "kind": "LinkedField",
      "name": "submitFeedback",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specialAnswer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "special",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "general",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskDescriptionList_task"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "order": "DESC"
          }
        }
      ],
      "concreteType": "UnitD",
      "kind": "LinkedField",
      "name": "publishedUnitDs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnitDItem_unitD"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblemSolveInfo",
      "kind": "LinkedField",
      "name": "problemSolveInfo",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskSpecialAnswer",
          "kind": "LinkedField",
          "name": "specialAnswers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rank",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "e097700f813cb7738352c5e61915a283";

export default node;
