import { CheckIcon } from '@primer/octicons-react';
import { noop } from 'lodash-es';
import { graphql, useFragment } from 'react-relay';

import { TaskTypingAutomationHistoryCreateDialog_task$key } from '../../../relay/__generated__/TaskTypingAutomationHistoryCreateDialog_task.graphql';
import { TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation } from '../../../relay/__generated__/TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation.graphql';
import { TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery } from '../../../relay/__generated__/TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery.graphql';
import { TaskTypingAutomationHistoryCreateDialog_typingAutomationScriptQuery } from '../../../relay/__generated__/TaskTypingAutomationHistoryCreateDialog_typingAutomationScriptQuery.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogButton from '../../core/DialogButton';
import Editor from '../../core/Editor';
import FetchQueryButton from '../../core/FetchQueryButton';
import Formik from '../../core/Formik';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import KatexEditor from '../../core/KatexEditor';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import RadioGroupField from '../../core/RadioGroupField';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import View from '../../core/View';
import TypingAutomationScriptPaginatorSearchOverlayField from '../../typingAutomationScript/TypingAutomationScriptPaginatorSearchOverlayField';

const TaskTypingAutomationHistoryCreateDialog_task = graphql`
  fragment TaskTypingAutomationHistoryCreateDialog_task on Task {
    id
    scrap {
      id
      solutions {
        id
        objectUrl
      }
      problems {
        id
        objectUrl
      }
    }
  }
`;

const typingAutomationScriptForTaskTypingAutomationHistoryCreateDialog = graphql`
  query TaskTypingAutomationHistoryCreateDialog_typingAutomationScriptQuery($id: ID!) {
    typingAutomationScript(id: $id) {
      id
      title
      description
    }
  }
`;

const typingAutomationPreviewForTaskTypingAutomationHistoryCreateDialog = graphql`
  query TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery($input: TypingAutomationPreviewInput!) {
    typingAutomationPreview(input: $input) {
      problem
      solution
    }
  }
`;

type Props = {
  task: TaskTypingAutomationHistoryCreateDialog_task$key;
  initialValues?: Partial<
    MutationFormikProps<TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation>['initialValues']
  >;
} & DialogProps &
  Pick<
    MutationFormikProps<TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const TaskTypingAutomationHistoryCreateDialog = ({
  task,
  config,
  onSubmit,
  connections,
  initialValues,
  ...props
}: Props) => {
  const { id, scrap } = useFragment(TaskTypingAutomationHistoryCreateDialog_task, task);

  return (
    <Dialog full {...props}>
      <MutationFormik<TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation>
        mutation={graphql`
          mutation TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation(
            $input: TypingAutomationHistoryCreateInput!
            $connections: [ID!]!
          ) {
            typingAutomationHistoryCreate(input: $input)
              @appendNode(edgeTypeName: "TypingAutomationHistoryEdge", connections: $connections) {
              id
            }
          }
        `}
        initialValues={{
          task: id,
          problem: '',
          solution: '',
          description: '',
          isDefault: true,
          ...initialValues,
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, values, setValues, setFieldValue }) => (
          <>
            <Dialog.Header>
              <Grid sx={{ paddingRight: 4, alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>프롬프트 생성하기</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Button leadingIcon={CheckIcon} variant={'primary'} onClick={() => handleSubmit()}>
                    {'생성하기'}
                  </Button>
                </Grid.Unit>
              </Grid>
            </Dialog.Header>
            <View height={'100%'} padding={5} overflowY={'hidden'}>
              <Grid wrap={false} sx={{ height: 'inherit' }} gapX={4}>
                <Grid.Unit size={3 / 4}>
                  <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 2 }}>
                    <View height={'50%'}>
                      <Grid gapX={2} sx={{ height: '100%' }}>
                        <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                          <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                            <Text fontSize={1}>스크랩한 문제</Text>
                            <Card flex={1} sx={{ overflow: 'auto' }}>
                              <ItemList
                                items={scrap?.problems || []}
                                renderItem={({ objectUrl }) => (
                                  <Image
                                    key={objectUrl}
                                    src={objectUrl}
                                    alt={objectUrl}
                                    html
                                    style={{ display: 'block' }}
                                  />
                                )}
                              />
                            </Card>
                          </View>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                          <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                            <Text fontSize={1}>문제</Text>
                            <Card flex={1} sx={{ overflow: 'auto' }}>
                              <Katex>{values.problem}</Katex>
                            </Card>
                          </View>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                          <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                            <Text fontSize={1} sx={{ visibility: 'hidden' }}>
                              문제 텍스트
                            </Text>
                            <Card flex={1} sx={{ overflow: 'auto' }}>
                              <KatexEditor height={'100%'} type={'scroll'} readOnly value={values.problem} />
                            </Card>
                          </View>
                        </Grid.Unit>
                      </Grid>
                    </View>
                    <View height={'50%'}>
                      <Grid gapX={2} sx={{ height: '100%' }}>
                        <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                          <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                            <Text fontSize={1}>스크랩한 해설</Text>
                            <Card flex={1} sx={{ overflow: 'auto' }}>
                              <ItemList
                                items={scrap?.solutions || []}
                                renderItem={({ objectUrl }) => (
                                  <Image
                                    key={objectUrl}
                                    src={objectUrl}
                                    alt={objectUrl}
                                    html
                                    style={{ display: 'block' }}
                                  />
                                )}
                              />
                            </Card>
                          </View>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                          <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                            <Text fontSize={1}>해설</Text>
                            <Card flex={1} sx={{ overflow: 'auto' }}>
                              <Katex>{values.solution}</Katex>
                            </Card>
                          </View>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                          <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                            <Text fontSize={1} sx={{ visibility: 'hidden' }}>
                              해설 텍스트
                            </Text>
                            <Card flex={1} sx={{ overflow: 'auto' }}>
                              <KatexEditor height={'100%'} type={'scroll'} readOnly value={values.solution} />
                            </Card>
                          </View>
                        </Grid.Unit>
                      </Grid>
                    </View>
                  </View>
                </Grid.Unit>
                <Grid.Unit size={1 / 4} sx={{ overflow: 'auto' }}>
                  <View height={'100%'} display={'flex'} flexDirection={'column'} overflowY={'auto'}>
                    <TextField name={'title'} label={'프롬프트 제목'} placeholder={'프롬프트 제목 입력'} />
                    <View mt={3}>
                      <Grid gapX={1}>
                        <Grid.Unit size={'max'}>
                          <Text fontSize={2} fontWeight={'bold'}>
                            프롬프트
                          </Text>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <DialogButton
                            renderDialog={({ isOpen, closeDialog }) => (
                              <Dialog isOpen={isOpen} onDismiss={closeDialog}>
                                <Dialog.Header>
                                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>불러오기</Text>
                                </Dialog.Header>
                                <Formik initialValues={{ typingAutomationScriptId: '' }} onSubmit={noop}>
                                  {({ values }) => (
                                    <>
                                      <Dialog.Body>
                                        <TypingAutomationScriptPaginatorSearchOverlayField
                                          label={'프롬프트 선택'}
                                          variables={{ order: { created: 'DESC' } }}
                                          name={'typingAutomationScriptId'}
                                          placeholder={'프롬프트 선택'}
                                        />
                                      </Dialog.Body>
                                      <Dialog.Footer>
                                        <FetchQueryButton<TaskTypingAutomationHistoryCreateDialog_typingAutomationScriptQuery>
                                          query={typingAutomationScriptForTaskTypingAutomationHistoryCreateDialog}
                                          variables={{ id: values.typingAutomationScriptId }}
                                          variant={'primary'}
                                          disabled={!values.typingAutomationScriptId}
                                          leadingIcon={CheckIcon}
                                          onComplete={(result) => {
                                            setFieldValue('title', result?.typingAutomationScript?.title);
                                            setFieldValue('description', result?.typingAutomationScript?.description);
                                            closeDialog();
                                          }}
                                        >
                                          불러오기
                                        </FetchQueryButton>
                                      </Dialog.Footer>
                                    </>
                                  )}
                                </Formik>
                              </Dialog>
                            )}
                          >
                            불러오기
                          </DialogButton>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <FetchQueryButton<TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery>
                            query={typingAutomationPreviewForTaskTypingAutomationHistoryCreateDialog}
                            variant={'outline'}
                            variables={{ input: { task: id, description: values.description } }}
                            onComplete={(result) => {
                              setFieldValue('problem', result?.typingAutomationPreview?.problem);
                              setFieldValue('solution', result?.typingAutomationPreview?.solution);
                            }}
                          >
                            결과 미리보기
                          </FetchQueryButton>
                        </Grid.Unit>
                      </Grid>
                    </View>
                    <View mt={3} flex={1}>
                      <Editor
                        height={'100%'}
                        type={'scroll'}
                        value={values.description}
                        onChange={(value) => setFieldValue('description', value)}
                      />
                    </View>
                    <View mt={3} flex={0}>
                      <RadioGroupField
                        label={'기본 프롬프트 설정'}
                        name={'isDefault'}
                        required
                        options={[
                          { id: 'true', text: '있음' },
                          { id: 'false', text: '없음' },
                        ]}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={1 / 2}>
                            {children}
                          </Grid.Unit>
                        )}
                        renderContainer={(children) => <Grid gapX={2}>{children}</Grid>}
                        value={values.isDefault === true ? 'true' : 'false'}
                        onChange={(selected) => {
                          const value = selected === 'true' ? true : false;
                          setValues({ ...values, isDefault: value });
                        }}
                      />
                    </View>
                  </View>
                </Grid.Unit>
              </Grid>
            </View>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskTypingAutomationHistoryCreateDialog;
