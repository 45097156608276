/**
 * @generated SignedSource<<39ab1159ba09ff053f960dd9254819c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type QuizStatusEnum = "error_report" | "published" | "review" | "%future added value";
export type QuizFilter = {
  AND?: QuizFilter | null;
  NOT?: QuizFilter | null;
  OR?: QuizFilter | null;
  answer_Exact?: string | null;
  answer_In?: ReadonlyArray<string> | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  status_Exact?: QuizStatusEnum | null;
  status_In?: ReadonlyArray<QuizStatusEnum> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitDId_In?: ReadonlyArray<string> | null;
  unitDTitle_Contains?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitTitle_Contains?: string | null;
};
export type QuizOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  sequence?: Ordering | null;
};
export type quiz_quizzesQuery$variables = {
  filters?: QuizFilter | null;
  order?: QuizOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type quiz_quizzesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QuizWindowPaginator_query">;
};
export type quiz_quizzesQuery = {
  response: quiz_quizzesQuery$data;
  variables: quiz_quizzesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "quiz_quizzesQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "QuizWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "quiz_quizzesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuizConnection",
        "kind": "LinkedField",
        "name": "quizzes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QuizEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Quiz",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitD",
                    "kind": "LinkedField",
                    "name": "unitD",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "picture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modified",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d379a06a03af378fe2c71e2e789f9a6e",
    "id": null,
    "metadata": {},
    "name": "quiz_quizzesQuery",
    "operationKind": "query",
    "text": "query quiz_quizzesQuery(\n  $filters: QuizFilter\n  $order: QuizOrder\n  $page: Int\n  $pageSize: Int\n) {\n  ...QuizWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment QuizConnectionDataTable_quizConnection on QuizConnection {\n  edges {\n    node {\n      id\n      ...QuizStatusLabel_quiz\n      sequence\n      unitD {\n        description\n        id\n      }\n      answer\n      createdBy {\n        id\n        ...UserAvatarText_user\n      }\n      created\n      modified\n    }\n  }\n}\n\nfragment QuizStatusLabel_quiz on Quiz {\n  status\n}\n\nfragment QuizWindowPaginator_query_36liK3 on Query {\n  quizzes(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...QuizConnectionDataTable_quizConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "5132ffcdb07849de47f32f9168122b00";

export default node;
