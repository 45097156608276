/**
 * @generated SignedSource<<acfb810b8809b53d1bd9d0270404af32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATStudentTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3_giha" | "high_school_3_hwaktong" | "high_school_3_mijukbun" | "high_school_n_giha" | "high_school_n_hwaktong" | "high_school_n_mijukbun" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATStudentConnectionDataTable_matStudentConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly friendTag: string;
      readonly id: string;
      readonly loginId: string;
      readonly studentType: MATStudentTypeEnum | null;
      readonly userId: number;
    };
  }>;
  readonly " $fragmentType": "MATStudentConnectionDataTable_matStudentConnection";
};
export type MATStudentConnectionDataTable_matStudentConnection$key = {
  readonly " $data"?: MATStudentConnectionDataTable_matStudentConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATStudentConnectionDataTable_matStudentConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATStudentConnectionDataTable_matStudentConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MATStudentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MATStudent",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "loginId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "studentType",
              "storageKey": null
            },
            {
              "alias": "userId",
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "friendTag",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MATStudentConnection",
  "abstractKey": null
};

(node as any).hash = "360988f8797967a9688eefefa2bc54f5";

export default node;
