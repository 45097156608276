/**
 * @generated SignedSource<<30acf9a5a152546c444bee040014c5ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "DESC" | "%future added value";
export type StoreStuffTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type StoreStuffFilter = {
  AND?: StoreStuffFilter | null;
  NOT?: StoreStuffFilter | null;
  OR?: StoreStuffFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  endAt_Exact?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  isValid?: boolean | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  period_In?: ReadonlyArray<string> | null;
  search?: string | null;
  type_In?: ReadonlyArray<StoreStuffTypeEnum> | null;
};
export type StoreStuffOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type StoreStuffPaginatorSearchOverlayField_storeStuffsQuery$variables = {
  after?: string | null;
  filters?: StoreStuffFilter | null;
  first?: number | null;
  order?: StoreStuffOrder | null;
};
export type StoreStuffPaginatorSearchOverlayField_storeStuffsQuery$data = {
  readonly storeStuffs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly stuff: {
          readonly id: string;
          readonly title: string;
        } | null;
        readonly title: string;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"StoreStuffPaginator_query">;
};
export type StoreStuffPaginatorSearchOverlayField_storeStuffsQuery = {
  response: StoreStuffPaginatorSearchOverlayField_storeStuffsQuery$data;
  variables: StoreStuffPaginatorSearchOverlayField_storeStuffsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Stuff",
  "kind": "LinkedField",
  "name": "stuff",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreStuffPaginatorSearchOverlayField_storeStuffsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "StoreStuffConnection",
        "kind": "LinkedField",
        "name": "storeStuffs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreStuffEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreStuff",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": (v4/*: any*/),
        "kind": "FragmentSpread",
        "name": "StoreStuffPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StoreStuffPaginatorSearchOverlayField_storeStuffsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "StoreStuffConnection",
        "kind": "LinkedField",
        "name": "storeStuffs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreStuffEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StoreStuff",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "StoreStuffPaginator_query_storeStuffs",
        "kind": "LinkedHandle",
        "name": "storeStuffs"
      }
    ]
  },
  "params": {
    "cacheID": "2ac956a6869e64a40067d5b27df4c48d",
    "id": null,
    "metadata": {},
    "name": "StoreStuffPaginatorSearchOverlayField_storeStuffsQuery",
    "operationKind": "query",
    "text": "query StoreStuffPaginatorSearchOverlayField_storeStuffsQuery(\n  $filters: StoreStuffFilter\n  $order: StoreStuffOrder\n  $first: Int\n  $after: String\n) {\n  storeStuffs(filters: $filters, order: $order, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        title\n        stuff {\n          id\n          title\n        }\n      }\n    }\n  }\n  ...StoreStuffPaginator_query_2rfEMg\n}\n\nfragment StoreStuffPaginator_query_2rfEMg on Query {\n  storeStuffs(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        title\n        stuff {\n          id\n          title\n        }\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2b4fdc74a167e3fa4730dcb8517442e";

export default node;
