import { CheckIcon } from '@primer/octicons-react';
import { GraphQLError } from 'graphql';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { BookActionsMutationButtonStack_book$key } from '../../../relay/__generated__/BookActionsMutationButtonStack_book.graphql';
import { BookActionsMutationButtonStack_bookRescrapMutation } from '../../../relay/__generated__/BookActionsMutationButtonStack_bookRescrapMutation.graphql';
import { BookActionsMutationButtonStack_bookSubmitMutation } from '../../../relay/__generated__/BookActionsMutationButtonStack_bookSubmitMutation.graphql';
import { parseGraphQLError } from '../../../utils/error';
import DialogButton from '../../core/DialogButton';
import MutationConfirmButton from '../../core/MutationConfirmButton';
import Stack from '../../core/Stack';
import BookBookScrapCreateDialog from '../BookBookScrapCreateDialog';

const BookActionsMutationButtonStack_book = graphql`
  fragment BookActionsMutationButtonStack_book on Book {
    id
    actions
    ...BookBookScrapCreateDialog_book
  }
`;

type Props = {
  book: BookActionsMutationButtonStack_book$key;
};

const BookActionsMutationButtonStack = ({ book: bookReference }: Props) => {
  const book = useFragment(BookActionsMutationButtonStack_book, bookReference);
  const { actions } = book;

  const { toast } = useToast();

  return (
    <Stack gapX={2}>
      {actions.includes('book_scrap_create') ? (
        <Stack.Item>
          <DialogButton
            variant={'outline'}
            renderDialog={({ isOpen, closeDialog }) => (
              <BookBookScrapCreateDialog book={book} isOpen={isOpen} onDismiss={closeDialog} full />
            )}
            size={'large'}
          >
            스크랩하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('book_submit') ? (
        <Stack.Item>
          <MutationConfirmButton<BookActionsMutationButtonStack_bookSubmitMutation>
            mutation={graphql`
              mutation BookActionsMutationButtonStack_bookSubmitMutation($input: BookSubmitInput!) {
                bookSubmit(input: $input) {
                  id
                  ...BookStatusLabel_book
                  ...BookDescriptionList_book
                  ...BookBookScrapCreateDialog_book
                  ...BookActionsMutationButtonStack_book
                  ...BookBookScrapPaginator_book
                }
              }
            `}
            input={{ id: book.id }}
            message={'제출한 스크랩은 수정할 수 없어요. 제출할까요?'}
            leadingIcon={CheckIcon}
            variant={'primary'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('스크랩을 먼저 완료해주세요', 'error');
              },
            }}
            size={'large'}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('book_rescrap') ? (
        <Stack.Item>
          <MutationConfirmButton<BookActionsMutationButtonStack_bookRescrapMutation>
            mutation={graphql`
              mutation BookActionsMutationButtonStack_bookRescrapMutation($input: BookRescrapInput!) {
                bookRescrap(input: $input) {
                  id
                  ...BookStatusLabel_book
                  ...BookDescriptionList_book
                  ...BookBookScrapCreateDialog_book
                  ...BookActionsMutationButtonStack_book
                  ...BookBookScrapPaginator_book
                }
              }
            `}
            input={{ id: book.id }}
            variant={'outline'}
            message={'상태를 진행 중으로 변경할까요?'}
            config={{
              onCompleted: () => {
                toast('상태 변경이 완료됐어요!', 'success');
              },
              onError: (error) => {
                toast(parseGraphQLError(error as GraphQLError)?.[0].message || '다시 제출해주세요', 'error');
              },
            }}
            size={'large'}
          >
            추가 작업하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default BookActionsMutationButtonStack;
