import { GraphQLError } from 'graphql';
import { camelCase } from 'lodash-es';
import { ReactNode, RefObject, useRef } from 'react';
import { GraphQLTaggedNode, useMutation, UseMutationConfig } from 'react-relay';
import { PayloadError } from 'relay-runtime';

import { useDialogContext } from '../../../contexts/DialogContext';
import { parseGraphQLError } from '../../../utils/error';
import { isFunction } from '../../../utils/is';
import { scrollIntoView, scrollToTop } from '../../../utils/scroll';
import Formik, { FormikConfig, FormikProps, FormikHelpers } from '../Formik';

const CONFIRM_TEXT = '작성 중인 내용이 있어요. 정말 나가실 건가요?';

type Props<
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input: {}; connections?: ReadonlyArray<string> };
    readonly rawResponse?: {} | undefined;
  },
> = {
  mutation: GraphQLTaggedNode;
  connections?: TMutation['variables']['connections'];
  config?: Omit<UseMutationConfig<TMutation>, 'variables' | 'onCompleted'> & {
    onCompleted?:
      | ((
          response: TMutation['response'],
          errors: PayloadError[] | null,
          formikHelpers: FormikHelpers<TMutation['variables']['input']>,
        ) => void | null)
      | undefined;
  };
  onSubmit?: FormikConfig<TMutation['variables']['input']>['onSubmit'];
  children?:
    | ReactNode
    | ((
        props: FormikProps<TMutation['variables']['input']>,
        {
          isMutationInFlight,
          nonFieldErrorRef,
          scrollContainerRef,
        }: {
          isMutationInFlight: boolean;
          nonFieldErrorRef: RefObject<HTMLDivElement>;
          scrollContainerRef: RefObject<HTMLDivElement>;
        },
      ) => ReactNode);
} & Omit<FormikConfig<Partial<TMutation['variables']['input']>>, 'onSubmit' | 'children'>;

const MutationFormik = <
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input: {}; connections?: ReadonlyArray<string> };
    readonly rawResponse?: {} | undefined;
  },
>({
  mutation,
  connections,
  config,
  onSubmit,
  children,
  ...props
}: Props<TMutation>) => {
  const [mutate, isMutationInFlight] = useMutation(mutation);
  const dialogContext = useDialogContext();

  dialogContext?.setConfirmText(CONFIRM_TEXT);

  const nonFieldErrorRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleSubmit = (
    values: TMutation['variables']['input'],
    formikHelpers: FormikHelpers<TMutation['variables']['input']>,
  ) => {
    const { setFieldError, setSubmitting } = formikHelpers;

    setSubmitting(true);

    onSubmit?.(values, formikHelpers);
    mutate({
      ...config,
      onError: (e) => {
        config?.onError?.(e);

        const errors = parseGraphQLError(e as GraphQLError);
        for (const error of errors || []) {
          const { field: _field, message } = error;
          const field = camelCase(_field || '') || 'nonFieldError';
          setFieldError(field, message);

          if (field === 'nonFieldError')
            setTimeout(() => {
              if (nonFieldErrorRef.current && scrollContainerRef.current)
                scrollIntoView(nonFieldErrorRef.current, scrollContainerRef.current, { behavior: 'smooth' });
            }, 0);
          else {
            if (scrollContainerRef.current) scrollToTop(scrollContainerRef.current, { behavior: 'smooth' });
          }
        }

        setSubmitting(false);
      },
      onCompleted: (response, errors) => {
        config?.onCompleted?.(response, errors, formikHelpers);

        setSubmitting(false);
      },
      variables: { connections: connections || [], input: { ...values } },
    });
  };

  return (
    <Formik<TMutation['variables']['input']> onSubmit={handleSubmit} enableReinitialize {...props}>
      {({ dirty, ...formikProps }) => {
        dialogContext?.setIsConfirm(dirty);
        return isFunction(children)
          ? children({ dirty, ...formikProps }, { isMutationInFlight, nonFieldErrorRef, scrollContainerRef })
          : children;
      }}
    </Formik>
  );
};

export default MutationFormik;
export type { Props as MutationFormikProps };
